import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * @description Mantém o atributo 'inputmode' do elemento com valor 'none'
 * e atribui o valor da propriedade inputmode da diretiva no elemento se no
 * evento de clique o input já estiver focado. Pode ser usado para fazer com
 * que o teclado virtual não seja exibido no primeiro clique feito no input.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
 *
 * @author Valdecir Correa <valdecir.correa@nexuscloud.com.br>
 */
@Directive({
  selector: 'input[doubleTabInputMode]',
  standalone: true,
})
export class DoubleTabInputModeDirective {
  @Input()
  inputmode: string = 'text';

  private get _inputElement(): HTMLInputElement {
    return this.elementRef.nativeElement as HTMLInputElement;
  }

  constructor(private elementRef: ElementRef) {
    this._setInputModeNone();
  }

  @HostListener('mousedown')
  private _onMouseDown(): void {
    if (this._inputElement.matches(':focus')) {
      this._inputElement.inputMode = this.inputmode;
    }
  }

  @HostListener('blur')
  private _setInputModeNone(): void {
    this._inputElement.inputMode = 'none';
  }
}
