import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

/**
 * @whatItDoes Disponibiliza um elemento para ser utilizado como float label.
 *
 * @description Esta diretiva possui cria um elemento que será utilizado como
 * float label. Inclui as classes necessárias para reproduzir os aspctos
 * visuais dos labels criados pela Angular Material.
 *
 * @class AstutusFloatLabelDirective
 */
@Directive({
  selector: `astutus-float-label, [astutusFloatLabel]`,
  host: {
    'class': 'astutus-float-label'
  }
})
export class AstutusFloatLabelDirective implements OnInit {

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const parent = this.renderer.parentNode(this.element.nativeElement);
    // Adicona os containers no elemento pai, para ganrantir o aspecto visual.
    this.renderer.addClass(parent, 'mat-form-field-infix');
    this.renderer.addClass(parent, 'astutus-float-label-container');
  }
}
