import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { EventsService } from '@app/core/services/events/events.service';
import { NotificationsService } from '@app/core/services/notifications/notifications.service';
import { firstValueFrom } from 'rxjs';

/**
 * Definição da classe.
 */
@Injectable()
export class OauthService {
  /**
   * Construtor.
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private eventsService: EventsService,
    private notificationsService: NotificationsService
  ) {}

  /**
   * Realiza o logout do usuário
   * e limpa o token de notificação
   * do usuario
   */
  async doLogout(isAuthorized = true) {
    const token = localStorage.getItem('oauth');

    if (token && isAuthorized) {
      try {
        await firstValueFrom(this.notificationsService.unsubscribe());
      } catch (err) {
        throw new Error('Erro ao limpar token de notificações.');
      }
    }

    AppConfig.OAUTH_DATA = undefined;
    AppConfig.EMPRESA_DATA = undefined;
    localStorage.clear();
    sessionStorage.clear();
    this.eventsService.disconnect();
    this.goToLogin();
  }

  /**
   * Navega para a tela de login
   */
  goToLogin() {
    this.goToHome().then(() => this.router.navigate(['/login']));
  }

  /**
   * Navega para a home
   */
  goToHome() {
    return this.router.navigate(['/app']);
  }

  /**
   * Realiza a autenticação.
   */
  public doLogin(user: string, pwd: string) {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: 'password',
      username: user,
      password: pwd,
    });

    return this.http.post(AppConfig.OAUTH_URL + '/login', body, { headers: headers });
  }

  /**
   * Atualiza o token.
   */
  public refreshToken(refreshToken) {
    const headers: HttpHeaders = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    });

    return this.http.post(AppConfig.OAUTH_URL + '/login', body, { headers: headers });
  }

  /**
   * Converte um objeto para uma string de parametros.
   */
  public buildFormUrlencodedParams(data: any): string {
    let params = '';

    for (const key in data) {
      params += key + '=' + data[key] + '&';
    }

    return params.substring(0, params.length - 1);
  }

  /**
   * Carrega o perfil do usuário
   */
  public getPerfilUsuario() {
    return this.http.get(AppConfig.API_URL_CADASTRO + '/perfil-usuario');
  }

  public exchangeToken(empresa) {
    return this.http.post(AppConfig.OAUTH_URL + '/token/exchange', empresa);
  }
}
