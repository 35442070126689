import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

/**
 * Diretiva que permite apenas digitar números e letras.
 */
@Directive({
  selector: '[appOnlyNumberText]',
})
export class OnlyNumberTextDirective {
  @Input() allowSpace: boolean = true;

  /**
   * Construtor
   * @param ngControl NGControl para controle dos dados.
   */
  constructor(private ngControl: NgControl) {}

  /**
   * Ao alterar o valor valida e remove os caracteres.
   */
  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const regex = this.allowSpace ? /[^a-zA-Z0-9\s]/g : /[^a-zA-Z0-9]/g;

    this.ngControl.control.patchValue(value.replace(regex, ''));
  }
}
