import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TrimDirective,
      multi: true,
    },
  ],
})
export class TrimDirective implements ControlValueAccessor {
  onTouched: any;

  onChange: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', this.trimValue(value));
  }

  @HostListener('input', ['$event'])
  onInput(_event: any) {
    this.onChange(this.trimValue(_event.target.value));
  }

  @HostListener('blur', ['$event'])
  onBlur(_event: any) {
    Object.assign(_event.target, {
      ..._event.target,
      value: this.trimValue(_event.target.value),
    });
    this.onTouched();
  }

  private trimValue(value: string) {
    const trimedValue = (value || '').trim();
    return trimedValue === '' ? null : trimedValue;
  }
}
