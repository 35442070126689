import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

/**
 * Custom dialog.
 *
 * Ex:
 *
 * {
 *  title: 'Deseja continuar?',
 *  content: ['Ao continuar, não poderá ser revertido'],
 *  buttons: [{
 *    text: 'CANCELAR',
 *    value: false,
 *    color: ''
 *  },
 * {
 *    text: 'CONFIRMAR',
 *    value: true,
 *    color: 'primary'
 *  }]
 * }
 */
@Component({
  selector: 'app-astutus-modal',
  templateUrl: './modal.component.html',
})
export class AstutusModalComponent {
  /**
   * Construtor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogRef: MatDialogRef<AstutusModalComponent>
  ) {
    dialogRef.disableClose = true;
  }
}
