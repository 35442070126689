import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AstutusCombobox } from './astutus-combobox.component';
import { AstutusComboboxTitle } from './astutus-combobox-title.component';
import { AstutusComboboxContent } from './astutus-combobox-content.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { DoubleTabInputModeDirective } from './directives/double-tab-input-mode.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatInputModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    DoubleTabInputModeDirective,
  ],
  declarations: [AstutusCombobox, AstutusComboboxTitle, AstutusComboboxContent],
  exports: [AstutusCombobox, AstutusComboboxTitle, AstutusComboboxContent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AstutusComboboxModule {}
