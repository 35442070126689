import { HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { EmailService } from '@app/services/emails/email.service';
import { SendEmailResponse } from '@app/services/emails/models/send-email-response';
import { SendEmailModel } from '@app/services/emails/models/send-email.model';
import { MediaType } from '@app/shared/enums/media-type.enum';
import { GeneratedReportModel } from '@app/shared/models/report/generated-report.model';
import { AbstractSubmitService, RequestResponse } from '@shared/http';
import { Observable } from 'rxjs';
import { NfeSaidaControlDef } from './control-defs';

@Injectable()
export class NotaSaidaService extends AbstractSubmitService<NfeSaidaControlDef> {
  get endPoint(): string {
    return `${this.apUrlVendas}/faturamento/nfe-saida`;
  }

  get EndPointRomaneio(): string {
    return `${this.apUrlVendas}/romaneio-entrega`;
  }

  private readonly emailService = inject(EmailService);

  searchPrecoList(produtoList, idListaPrecoCliente = null, idListaPrecoVendedor = null): any {
    const url = `${this.apiUrlCadastro}/lista-preco/preco-venda`;
    return this.http.post(url, {
      produtoList,
      idListaPrecoCliente,
      idListaPrecoVendedor,
    });
  }

  /**
   * Efetua a baixa dos títulos.
   *
   * @returns {Observable<RequestResponse<any>>}
   */
  saveBaixaTitulo(data: any): Observable<RequestResponse<any>> {
    return this.http.post<RequestResponse<any>>(
      `${AppConfig.API_URL_FINANCEIRO}/titulo/baixa`,
      data
    );
  }

  /**
   * Emite relatório da nota fiscal
   */
  getRelatorioNota(idLivroFiscal: number): Observable<RequestResponse<GeneratedReportModel>> {
    const url = `${this.apiUrlFiscal}/nota-saida/${idLivroFiscal}/pdf`;
    return this.http.get<RequestResponse<GeneratedReportModel>>(url);
  }

  getNota(idLivroFiscal: number) {
    const url = `${this.apiUrlFiscal}/livro-fiscal/${idLivroFiscal}`;
    return this.http.get<RequestResponse<any>>(url);
  }

  exclusaoLogica(data: any) {
    const url = `${this.apiUrlFiscal}/livro-fiscal/excluir-nota`;

    return this.http.put<RequestResponse<any>>(url, data);
  }

  /**
   * Envia um email com os dados da nota emitida.
   */
  enviarEmailNota(
    pdfData: GeneratedReportModel,
    livroFiscal,
    destinatario
  ): Observable<SendEmailResponse[]> {
    const xmlFilename = `${livroFiscal.nrIdentificador}.xml`;
    const sendEmailModel: SendEmailModel = {
      mensagem: 'Emitido uma nota fiscal em seu nome',
      toEmail: [
        {
          nome: destinatario.nmPessoa,
          address: destinatario.dsEmail,
        },
      ],
      attachments: [
        {
          filename: pdfData.nmFilename.split('/').pop(),
          contentType: MediaType.applicationPdf,
          objectKey: `${pdfData.clientId}/${pdfData.nmDownload}`,
        },
        {
          filename: xmlFilename,
          contentType: MediaType.textXml,
          objectKey: `${pdfData.clientId}/${xmlFilename}`,
        },
      ],
    };

    return this.emailService.sendRelatorio(sendEmailModel);
  }

  /**
   * Busca produtos pelo id do faturamento.
   *
   * @param {number} id do faturamento
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  getProdutos(id: number): Observable<RequestResponse<any>> {
    return this.http.get<RequestResponse<any>>(
      `${AppConfig.API_URL_VENDAS}/faturamento/${id}/itens`
    );
  }

  /**
   * Busca valor da nota fiscal e produtos pelo id do faturamento
   *
   * @param {number} id do faturamento
   *
   */
  getFaturamentoProdutos(idFaturamento: number) {
    return this.http.get<RequestResponse<any>>(
      `${AppConfig.API_URL_VENDAS}/faturamento/${idFaturamento}/detalhe`
    );
  }

  /**
   * Imprime romaneio de entrega.
   */
  printRomaneioEntrega(idFaturamento: number) {
    const params = new HttpParams().set('search', `id_faturamento:${idFaturamento}`);
    const url = `${this.EndPointRomaneio}/relatorio/pdf`;
    return this.http.get<RequestResponse<any>>(url, { params });
  }
}
