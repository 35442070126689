import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SolicitacaoService } from '@app/pages/solicitacao/solicitacao.service';
import { AuthenticateDialogParams } from '@app/services/processos/authenticate/params.model';
import { AutorizaSolicitacaoModel } from '@app/services/processos/models/autoriza-solicitacao.model';
import { PrivilegioAprovadorModel } from '@app/services/processos/models/privilegio-aprovador.model';
import { SolicitacaoModel } from '@app/services/processos/models/solicitacao.model';
import { RequestResponse } from '@app/shared/http';
import { take } from 'rxjs/operators';
import { KeyCode } from '@app/shared/enums/keycode.enum';
import { UsuarioControlDefs } from './control-defs';

@Component({
  selector: 'app-authenticate',
  templateUrl: 'authenticate-dialog.component.html',
})
export class AuthenticateDialogComponent implements OnInit {
  form: UntypedFormGroup;

  aprovadorList: PrivilegioAprovadorModel[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AuthenticateDialogParams,
    public dialogRef: MatDialogRef<AuthenticateDialogComponent>,
    private fb: UntypedFormBuilder,
    private solicitacaoService: SolicitacaoService
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.aprovadorList = this.data.privilegioAprovadorList.filter(
      (item, index, currentList) =>
        currentList.findIndex(value => value.usuario.idUsuario === item.usuario.idUsuario) === index
    );
    if (this.aprovadorList && this.aprovadorList.length === 1) {
      this.form.get(this.controlDefs.USER).setValue(this.aprovadorList[0]);
    }
  }

  get controlDefs() {
    return UsuarioControlDefs;
  }

  async authorize() {
    const aprovador: PrivilegioAprovadorModel = this.form.get(this.controlDefs.USER).value;

    const data: AutorizaSolicitacaoModel = {
      idSolicitacao: this.data.idSolicitacao,
      usuario: {
        idUsuario: aprovador.usuario.idUsuario,
        dsSenha: this.form.get(this.controlDefs.PASSWORD).value,
      },
    };

    this.solicitacaoService
      .autorizar(data)
      .pipe(take(1))
      .subscribe(
        (response: RequestResponse<SolicitacaoModel>) => {
          this.dialogRef.close(response.data);
        },
        error => {
          if (error.error.message === 'Usuário e/ou senha inválido(s)') {
            this.form.get(this.controlDefs.PASSWORD).setErrors({ invalid: error.error.message });
          }
        }
      );
  }

  onKeyUp(event: KeyboardEvent) {
    if (event.key === KeyCode.ENTER) {
      this.authorize();
    }
  }

  private initForm() {
    this.form = this.fb.group({
      [this.controlDefs.USER]: [null, [Validators.required]],
      [this.controlDefs.PASSWORD]: [null, [Validators.required]],
    });
  }
}
