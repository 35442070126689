import { AstutusComboboxUtil } from './astutus-combobox-util';
import { HttpClient } from '@angular/common/http';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Messages } from '@bower-components/astutus-formulario/messages';
import { Directive } from "@angular/core";

@Directive()
export abstract class AstutusComboboxRequestUtil extends AstutusComboboxUtil {
  /**
   * Indica se já foi feito algum request.
   */
  protected firstRequest = true;

  /**
   * Pagina atual do request.
   */
  protected paginaAtual: number;

  /**
   * Total de páginas.
   */
  protected totalPaginas: number;

  /**
   * Lista de mensagens exibidas no toast.
   */
  MESSAGES = Messages.MESSAGES;

  /**
   * Construtor.
   */
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    super();
  }

  /**
   * Ativa o progressbar e realiza o request para busca da página exibida no combobox.
   */
  protected doRequest(force = false) {
    this.enableProgressBar();

    if (force) {
      this.items = [];
      this.paginaAtual = 0;
    }

    const searchValue = encodeURI(
      [...this.getSearchParameters(), ...this.getAdditionaParameters()].join(',')
    );

    this.http.get(`${this.url}?search=${searchValue}`).subscribe(
      (data) => this.onRequestSuccess(data),
      (error) => {
        if (error.status == 0) {
          this.showMessage(this.MESSAGES.TOAST.TIME_OUT_ERROR);
        }
        this.disableProgressBar();
      }
    );
  }

  /**
   * Monta a lista de parametros.
   */
  private getSearchParameters() {
    const search = [`pag:${this.paginaAtual ? ++this.paginaAtual : 1}`];

    if (this.orderField) {
      search.push(`orderField:${this.orderField}`);
    }

    if (this.orderType) {
      search.push(`orderType:${this.orderType}`);
    }

    if (this.searchField && this.searchText !== undefined) {
      search.push(`${this.searchField}:${this.searchText}`);
    }

    return search;
  }

  /**
   * Retorna os parametros adicionais formatados.
   */
  private getAdditionaParameters() {
    return (this.additionalParameters || []).map(
      (parameter) => `${parameter.param}${parameter.operation}${parameter.value}`
    );
  }

  /**
   * Ao buscar os dados do combo, Atualiza os itens, total de páginas e desabilita o progressbar.
   */
  private onRequestSuccess(response) {
    let items = [];

    if (this.items && response.data.paginaAtual > 1) {
      items = this.items;
    }

    this.paginaAtual = response.data.paginaAtual;
    this.totalPaginas = response.data.totalPaginas;
    this.items = items.concat(response.data.data);
    this.filteredItems = this.items;

    this.firstRequest = false;

    if (!this.filteredItems.length) {
      this.firstRequest = true;
      this.paginaAtual = 0;
    }

    if (this.filteredItems.length === 1 && this.selectOneResult) {
      this.selectOneResultEvent.emit(this.filteredItems[0]);
    }

    this.disableProgressBar();
    const evt = window.document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);

    const isSelectedValueNotNull = this.selectedValue != null;
    const isSelectedItemNotNull = this.selected != null;

    if (isSelectedValueNotNull && isSelectedItemNotNull) {
      const itemSelectedIsNotPresentInList = this.filteredItems.some(item => {
        const isItemNotNull = item != null;

        if (isItemNotNull) {
          return item[this.selectedValue] != this.selected[this.selectedValue];
        }
        return false;
      });

      if (itemSelectedIsNotPresentInList) {
        this.formControlRef.setValue(null);
      }
    }

    window.dispatchEvent(evt);
  }

  private showMessage(msg: string) {
    this.snackBar.open(msg, 'OK', <MatSnackBarConfig>{
      duration: 4000,
    });
  }
}
