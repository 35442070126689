/* eslint-disable max-classes-per-file */
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EMPTY, fromEvent, of } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { AstutusComboboxRequestUtil } from './astutus-combobox-requests';
import { KeyCode } from './key-code.enum';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  constructor(private getForm: Function) { }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const formControl: UntypedFormControl = this.getForm();
    return formControl.touched && !!formControl.errors;
  }
}

@Component({
  selector: 'astutus-combobox',
  templateUrl: './astutus-combobox.component.html',
  styleUrls: ['./astutus-combobox.component.scss'],
  host: {
    '[attr.id]': 'id',
    class: 'astutus-combobox',
  },
})
export class AstutusCombobox
  extends AstutusComboboxRequestUtil
  implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  /**
   * Import do componente para controlar o scroll das novas páginas.
   */
  @ViewChild('auto') private autoCompleteComponent: MatAutocomplete;

  /**
   * Import do input para bind de key.
   */
  @ViewChild('inputElement') private inputElement: ElementRef;

  /**
   * Import do input.
   */
  @ViewChild(MatAutocompleteTrigger) inputAutoComplete: MatAutocompleteTrigger;

  /**
   * Referência para o mat option.
   */
  @ViewChild('matoption') matOption: any;

  /**
   * Listener do form do input.
   *
   * Utilizado para remover listener ao destruir o componente.
   */
  private formControlListener: any;

  /**
   * Matcher utilizado para gerenciar o erro do input usando o form passado da tela.
   */
  matcher = new MyErrorStateMatcher(() => this.formControlRef);

  private cancelInputChange = false;

  /**
   * Construtor.
   * Recebe o http para busca dos dados.
   */
  constructor(http: HttpClient, snackBar: MatSnackBar) {
    super(http, snackBar);
  }

  ngOnInit() {
    if (!this.hasRequest) {
      this.filteredItems = this.items;
    }

    this.formControlListener = this.formControlRef.valueChanges.subscribe(value => {
      this.formControl.setValue(value);
      this.selectedChange.emit(value);
    });

    this.formControlRef.statusChanges.subscribe(status => {
      if (status === 'DISABLED' && this.formControl.enabled) {
        this.formControl.disable();
      }

      if (status !== 'DISABLED' && this.formControl.disabled) {
        this.formControl.enable();
      }
    });

    this.selectOneResultEvent.subscribe(value => {
      this.onSelect(value);
      this.inputAutoComplete.closePanel();
    });

    setTimeout(() => this.formControlRef.updateValueAndValidity());
  }

  ngOnChanges(changes: any) {
    if (changes.items) {
      this.filteredItems = changes.items.currentValue;
    }

    if (this.hasRequest && !this.firstRequest && changes.additionalParameters) {
      this.doRequest(true);
    }
  }

  ngAfterViewInit(): void {
    this.keyupInputDebounceTime();
  }

  /**
   * Ao clicar no input, abre o modal.
   *
   * Evento adicionado, pois após selecionar um valor, quando clicado, não abria por padrão.
   */
  onInputClick($event) {
    $event.preventDefault();
    $event.target.select();

    this.inputAutoComplete.openPanel();
  }

  /**
   * Manipula o overlay do combobox quando o input recebe o foco.
   */
  onInputFocus(event): void {
    if (this.firstRequest && this.hasRequest) {
      this.doRequest();
    }

    setTimeout(() => this.addScrollRequestEvent());
  }

  onInputArrowClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.autoCompleteComponent.isOpen) {
      this.inputAutoComplete.closePanel();
      return;
    }

    this.inputElement.nativeElement.click();
  }

  onBlur() {
    this.formControlRef.markAsTouched();

    if (this.searchText !== undefined && this.searchText !== '') {
      this.searchText = undefined;
      this.formControlRef.setValue(undefined);
    }

    this.blur.emit();
  }

  onSelect(item) {
    this.cancelInputChange = true;
    setTimeout(() => (this.cancelInputChange = false), 1000);

    this.searchText = undefined;

    this.formControlRef.setValue(item);
    this.formControlRef.markAsTouched();
    this.optionSelected.emit(item);
  }

  /**
   * Reseta o estado do combobox, fazendo com que ele fique
   * no estado inicial, antes da interação do usuário.
   *
   * @AVISO - A chamada desta função é extremamente necessária para a utilização do combobox sem o formControl,
   * caso a função de reset não seja chamada, o combobox irá manter o seu valor atual.
   */
  reset(): void {
    this.firstRequest = true;
    this.paginaAtual = 0;
    this.searchText = undefined;
    this.filteredItems = this.items;
  }

  private filter(filter: string | undefined) {
    let filteredItems = this.items;

    if ((filter || filter === '0') && this.items) {
      filteredItems = this.items.filter(item => {
        const itemValue = this.selectedLabel ? eval(`item.${this.selectedLabel}`) : item;

        if (typeof itemValue === 'number') {
          return itemValue.toString() === filter;
        }

        return itemValue.toUpperCase().indexOf(filter.toUpperCase()) >= 0;
      });
    }

    this.filteredItems = filteredItems;

    if (this.filteredItems.length === 1 && this.selectOneResult) {
      this.selectOneResultEvent.emit(this.filteredItems[0]);
    }
  }

  /**
   * Remove o evento de scroll caso já esteja adicionado.
   * Adiciona o evento para realizar requests ao bater no fim do panel.
   */
  private addScrollRequestEvent() {
    if (!this.autoCompleteComponent.panel) {
      return;
    }

    this.autoCompleteComponent.panel.nativeElement.addEventListener('scroll', (event: any) => {
      const top = event.srcElement.scrollTop;
      const heigth = this.matOption._element.nativeElement.offsetHeight;
      if (top + 252 >= heigth * this.items.length && this.paginaAtual < this.totalPaginas) {
        this.doRequest();
      }
    });
  }

  /**
   * Registra um evento para manipular o valor digitado no input com 1000ms de delay.
   */
  private keyupInputDebounceTime(): void {
    const ignoreKeyCodes: string[] = [
      KeyCode.TAB,
      KeyCode.SHIFT,
      KeyCode.ARROW_LEFT,
      KeyCode.ARROW_UP,
      KeyCode.ARROW_RIGHT,
      KeyCode.ARROW_DOWN,
    ];
    fromEvent<KeyboardEvent>(this.inputElement.nativeElement, 'keyup')
      .pipe(
        switchMap(event => {
          const shouldSkipEvent = this.cancelInputChange || ignoreKeyCodes.includes(event.key);
          return shouldSkipEvent ? EMPTY : of(event);
        }),
        tap(event => {
          const element = event.target as HTMLInputElement;
          const value = element.value ? element.value.trim() : '';
          this.searchText = value.length ? value : undefined;

          if (this.searchText === undefined) {
            this.formControlRef.setValue(undefined);
          }
        }),
        debounceTime(1000)
      )
      .subscribe(() => {
        if (this.hasRequest) {
          this.doRequest(true);
        } else {
          this.filter(this.searchText);
        }
      });
  }

  stopPropagation($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  /**
   * Ao destruir o componente, elimina os eventos.
   */
  ngOnDestroy() {
    this.formControlListener.unsubscribe();
  }

  getContext() {
    return 'context:filteredItems;';
  }

  focus(): void {
    this.inputElement.nativeElement.focus();
  }
}
