import { ProdutoModel } from './../../pages/cadastro/produto/produto/model/produto-model';
import { ProdutoComboModel } from '../models/commons/produto-combo-model';
import { Injectable } from "@angular/core";

/**
 * Service para a conversão dos models de Produto
 */
@Injectable()
export class ProdutoConverterService {
  /**
   * Método que converte um produto para
   * um combo produto
   * @param produto
   */
  convertProdutoToComboModel(produto: ProdutoModel): ProdutoComboModel {
    let produtoCombo: ProdutoComboModel = {
      cdProduto: produto.cdProduto,
      dsCodigoBarras: produto.dsCodigoBarras,
      dsProduto: produto.dsProduto,
      idProduto: produto.idProduto,
      nrEstoqueDisponivel: produto.nrEstoqueDisponivel,
      tpControleLote: produto.tpControleLote,
      vlVenda: produto.vlVenda,
    };
    return produtoCombo;
  }

  /**
   * Método que converte um comboProduto para
   * Produto
   * @param produto
   */
  convertComboToProdutoModel(produtoCombo: ProdutoComboModel): ProdutoModel {
    let produto: ProdutoModel = {
      cdProduto: produtoCombo.cdProduto,
      dsCodigoBarras: produtoCombo.dsCodigoBarras,
      dsProduto: produtoCombo.dsProduto,
      idProduto: produtoCombo.idProduto,
      nrEstoqueDisponivel: produtoCombo.nrEstoqueDisponivel,
      tpControleLote: produtoCombo.tpControleLote,
      vlVenda: produtoCombo.vlVenda,
    };
    return produto;
  }
}
