import { Injectable } from '@angular/core';
import { ProdutoService } from '@app/pages/cadastro/produto/produto/produto.service';
import { HistoricoProdutoDialogComponent } from '@app/shared/components/historico-produto-dialog/historico-produto-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class HistoricoProdutoService {

  /**
   * Construtor.
   */
  constructor(private apiService: ProdutoService) { }

  /**
   * Abre o modal.
   */
  openModal(dialog, idProduto): Observable<Object> {

    const data = new Observable(observer => {
      this.apiService.getHistorico(idProduto).subscribe(response => {
        dialog.open(HistoricoProdutoDialogComponent, {
          width: '870px',
          maxWidth: 'none',
          data: response.data
        });
        observer.next();
      });
    });

    return data;
  }
}
