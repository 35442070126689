import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { Observable, firstValueFrom, timeout } from 'rxjs';
import { DownloadService } from './download.service';

/**
 * Serviço para impressão e download de arquivo
 */
@Injectable({ providedIn: 'root' })
export class DownloadFilesService {
  constructor(private downloadService: DownloadService, private http: HttpClient) {}

  getFile(url, body = null, folderName = null) {
    if (body == null) {
      return this.http.get(url, {
        responseType: 'blob',
        headers: {
          Authorization: this.getAuthorization(),
          'Content-Type': 'application/octet-stream',
        },
        params: folderName !== null ? new HttpParams().set('folderName', folderName) : null,
      });
    }
    return this.http.post(url, body, {
      responseType: 'blob',
      headers: {
        Authorization: this.getAuthorization(),
        'Content-Type': 'application/json',
      },
      params: folderName !== null ? new HttpParams().set('folderName', folderName) : null,
    });
  }

  downloadFile(url, extensao = null, name = null, folderName = null) {
    return new Observable(observer => {
      this.getFile(url, null, folderName).subscribe(content => {
        name = name || url;
        if (extensao) {
          this.downloadService.download(name + extensao, content);
        } else {
          this.downloadService.download(name, content);
        }
        observer.next();
      });
    });
  }

  async downloadFileSync(url, extensao = null, body = null, name = null, folderName = null) {
    const content = await this.getFile(url, body, folderName).toPromise();
    name = name || url;
    if (extensao) {
      await this.downloadService.download(name + extensao, content);
    } else {
      await this.downloadService.download(name, content);
    }
  }

  private async checkStatusPrintServer(): Promise<number> {
    return firstValueFrom(
      this.http.get<number>(`http://localhost:8765/status`).pipe(timeout(3000))
    );
  }

  async sendToPrinter(file): Promise<any> {
    try {
      firstValueFrom(this.http.post<any>(`http://localhost:8765/print-out`, file));
    } catch (error) {
      throw new Error('Erro ao enviar arquivo para impressão');
    }
  }

  printFile(url: string) {
    return new Observable(observer => {
      this.getFile(url).subscribe(async content => {
        const pdfLocalFile = new window.Blob([content], {
          type: 'application/pdf',
        });
        firstValueFrom(this.printBlob(pdfLocalFile), { defaultValue: null });
        observer.complete();
      });
    });
  }

  printBlob(blob: Blob) {
    return new Observable(observer => {
      const pdfLocalUrl = window.URL.createObjectURL(blob);

      this.checkStatusPrintServer()
        .then(() => {
          this.sendToPrinter(blob);
          observer.complete();
          return;
        })
        .catch(() => {
          const printFrame = document.createElement('iframe');
          printFrame.className = 'print-frame';
          printFrame.style.display = 'none';

          document.body.appendChild(printFrame);

          printFrame.onload = () => {
            const onWindowsFocus = () => {
              window.removeEventListener('focus', onWindowsFocus);
              window.URL.revokeObjectURL(pdfLocalUrl);

              printFrame.remove();
              observer.complete();
            };

            printFrame.focus();
            printFrame.contentWindow.print();

            window.addEventListener('focus', onWindowsFocus);
          };

          printFrame.setAttribute('src', pdfLocalUrl);
        });
    });
  }

  getAuthorization() {
    return `Bearer ${AppConfig.OAUTH_DATA.access_token}`;
  }
}
