<h1 mat-dialog-title *ngIf="!isSolicitacaoRecusada">Solicitar acesso</h1>
<h1 mat-dialog-title class="recusada-title" *ngIf="isSolicitacaoRecusada">Solicitação recusada</h1>
<div mat-dialog-content class="msg-autorizacao" [formGroup]="validatorForm">
  <div class="msg-autorizacao-content">
    <div *ngFor="let item of privilegioSolicitacaoControls; let i = index">
      <div class="processo-item" [formGroup]="item">
        <span class="processo-title">Processos:</span>
        <div>
          {{ item.get(this.privilegioSolicitacaoControlDefs.DESCRICAO).value }}
        </div>
      </div>
    </div>

    <div row-wrap>
      <mat-form-field col-12>
        <input
          matInput
          placeholder="Observação (opcional)"
          [formControl]="this.validatorForm.get(this.controlDefs.OBSERVACAO)"
        />
      </mat-form-field>
      <mat-form-field col-12>
        <textarea
          matInput
          matTextareaAutosize
          matAutosizeMinRows="1"
          matAutosizeMaxRows="2"
          placeholder="Número da Solicitação"
          [formControlName]="this.controlDefs.ID_SOLICITACAO"
        >
        </textarea>
      </mat-form-field>
      <mat-form-field col-12 *ngIf="isSolicitacaoRecusada">
        <textarea
          matInput
          matTextareaAutosize
          matAutosizeMinRows="1"
          matAutosizeMaxRows="3"
          placeholder="Motivo da rejeição"
          [formControl]="this.validatorForm.get(this.controlDefs.MOTIVO_REJEICAO)"
        >
        </textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="processing-wrapper" *ngIf="isProcessing">
    <mat-spinner diameter="28" color="accent"></mat-spinner>
    <div class="msg-text" *ngIf="isProcessing">{{ getProcessingTitle() }}</div>
  </div>
</div>

<mat-dialog-actions align="end">
  <button mat-button (click)="cancelar()" *ngIf="isSolicitacaoRecusada">FECHAR</button>
  <button mat-button (click)="cancelar()" *ngIf="!isSolicitacaoRecusada">CANCELAR</button>
  <button mat-button color="accent" (click)="autorizarAcesso()" [disabled]="isProcessing">
    AUTORIZAR
  </button>
  <button
    mat-button
    color="primary"
    (click)="solicitarAcesso()"
    [disabled]="(isSolicitacaoLocal && !isSolicitacaoRecusada) || isProcessing"
  >
    SOLICITAR ACESSO
  </button>
</mat-dialog-actions>
