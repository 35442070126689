import { CustomValidators } from '@app/shared/validators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl, Validators } from '@angular/forms';
import { AbstractFormatter } from '@shared/directives/abstract-formatter';
import { Directive, Renderer2, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[cpfCnpjFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CpfCnpjFormatDirective,
      multi: true,
    },
  ],
})
export class CpfCnpjFormatDirective extends AbstractFormatter implements ControlValueAccessor {
  onTouched: any;

  onChange: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private getFormat(value): string {
    value = value.toString();
    if (value && value.length > 11) {
      return '99.999.999/9999-99';
    }
    return '999.999.999-99';
  }

  writeValue(value: any): void {
    const clearedValue = this.clearValue(value || '');
    const formatedValue = this.formatValue(value || '', this.getFormat(clearedValue));
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', formatedValue);
  }

  @HostListener('input', ['$event'])
  onInput(_event: any) {
    const clearedValue = this.clearValue(_event.target.value);
    const formatedValue = this.formatValue(clearedValue, this.getFormat(clearedValue));
    _event.target.value = formatedValue;
    this.onChange(this.clearValue(formatedValue));
  }

  @HostListener('blur', ['$event'])
  onBlur(_event: any) {
    this.onTouched();
  }
}
