import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { OauthService } from './oauth/oauth.service';

/**
 * Verifica se o usuário já está logado e manda para a dashboard.
 */
@Injectable()
export class AuthLoginGuard  {
  /**
   * Construtor.
   */
  constructor(private oauthService: OauthService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const oauth = localStorage.getItem('oauth');

    if (oauth) {
      this.oauthService.goToHome();
    }

    return !oauth;
  }
}
