import { Component } from '@angular/core';

@Component({
  selector: 'astutus-combobox-title',
  templateUrl: './astutus-combobox-title.component.html',
  styleUrls: ['./astutus-combobox-title.component.scss'],
})
export class AstutusComboboxTitle {
  constructor() {}
}
