import { ItemComboboxModel } from '@app/shared/models/item-combobox';

export class TipoSolicitacao {
  static readonly REMOTA = 0;

  static readonly LOCAL = 1;

  private static map = {
    [TipoSolicitacao.REMOTA]: 'Remota',
    [TipoSolicitacao.LOCAL]: 'Local',
  };

  /**
   * Obtém a descrição do tipo da solicitação
   *
   * @param tpSolicitacao
   */
  static getDescricao(tpSolicitacao: number): string {
    return this.map[tpSolicitacao];
  }

  /**
   * Retorna os valores enumerados como lista
   *
   * @returns
   */
  static values(): ItemComboboxModel[] {
    return [
      { value: this.REMOTA, label: this.getDescricao(this.REMOTA) },
      { value: this.LOCAL, label: this.getDescricao(this.LOCAL) },
    ];
  }
}
