import { Injectable } from '@angular/core';
import { NotaSaidaService } from '@app/shared/components/nota-fiscal/utils/nota-saida.service';
import { Observable, Subject } from 'rxjs';

/**
 * Classe para buscar os dados dos produtos relacionados.
 */
@Injectable()
export class ProdutoSimilarAgregadoService {
  /**
   * Construtor.
   */
  constructor(private notaSaidaService: NotaSaidaService) {}

  /**
   * Formata a lista de produtos agregados e similares para adicionar o valor de venda.
   */
  formatarProdutosRelacionados(
    produtoAgregadoList,
    produtoSimilarList,
    idListaPreco
  ): Observable<any> {
    produtoAgregadoList = produtoAgregadoList
      .filter(item => item.itemProdutoAgregado.tpSituacao)
      .map(item => item.itemProdutoAgregado);

    produtoSimilarList = produtoSimilarList
      .filter(item => item.itemProdutoSimilar.tpSituacao)
      .map(item => item.itemProdutoSimilar);

    return this.getVlVenda(produtoAgregadoList, produtoSimilarList, idListaPreco);
  }

  /**
   * Busca o valor de venda no preços e atribui as listas de produtos agregados e similares.
   */
  private getVlVenda(produtoAgregadoList, produtoSimilarList, idListaPreco): Observable<any> {
    var subject = new Subject<any>();

    this.notaSaidaService
      .searchPrecoList(this.getProdutoList(produtoAgregadoList, produtoSimilarList), idListaPreco)
      .subscribe(
        response => {
          const vlVendaList = response.data.produtoList;

          this.setValorVenda(produtoAgregadoList, vlVendaList);
          this.setValorVenda(produtoSimilarList, vlVendaList);

          subject.next({
            produtoAgregadoList: produtoAgregadoList,
            produtoSimilarList: produtoSimilarList,
          });
        },
        () => subject.next(null)
      );

    return subject.asObservable();
  }

  /**
   * Seta o valor de venda dos itens da lista de produtos.
   */
  private setValorVenda(produtoList, vlVendaList) {
    produtoList.forEach(
      produto =>
        (produto.vlVenda = vlVendaList.find(item => item.idProduto == produto.idProduto).vlVenda)
    );
  }

  /**
   * Monta a lista de produtos a ser buscada. Filtrando para não buscar o mesmo.
   */
  private getProdutoList(produtoAgregadoList, produtoSimilarList) {
    const produtoList = new Set([
      ...produtoAgregadoList.map(item => item.idProduto),
      ...produtoSimilarList.map(item => item.idProduto),
    ]);

    return Array.from(produtoList).map(id => {
      return { idProduto: id };
    });
  }
}
