import { Component } from '@angular/core';

@Component({
  selector: 'astutus-combobox-content',
  templateUrl: './astutus-combobox-content.component.html',
  styleUrls: ['./astutus-combobox-content.component.scss'],
})
export class AstutusComboboxContent {
  constructor() {}
}
