<h1 mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <div *ngFor="let content of data.content">{{ content }}</div>
</div>
<mat-dialog-actions align="end">
  <button
    *ngFor="let button of data.buttons"
    mat-button
    [mat-dialog-close]="button.value"
    [color]="button.color"
  >
    {{ button.text }}
  </button>
</mat-dialog-actions>
