import { Observable, Subscriber } from 'rxjs';

/**
 * Cria um `Observable` que emite eventos quando um ou mais elementos são redimensionados.
 * Certifique-se de fazer o `unsubscribe` adequadamente no Observable.
 * @emits `ResizeObserverEntry[]`
 * @author Valdecir Correa <valdecir.correa@nexuscloud.com.br>
 */
export default function fromResize(...elements: Element[]): Observable<ResizeObserverEntry[]> {
  return new Observable((subscriber: Subscriber<ResizeObserverEntry[]>) => {
    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) =>
      subscriber.next(entries)
    );

    elements.forEach(elem => resizeObserver.observe(elem));

    return () => {
      resizeObserver.disconnect();
      subscriber.unsubscribe();
    };
  });
}
