import { Injectable } from '@angular/core';

/**
 * Service para carregar os dados do sessionStorage.
 */
@Injectable()
export class SessionStorageService {

  /**
   * Construtor
   */
  constructor() {
  }

  /**
   * Retorna o objeto salvo na key passada, caso encontrado.
   */
  public getItem(key: string) {
    let data = sessionStorage.getItem(key);

    if (data) {
      return JSON.parse(data);
    }
  }

}
