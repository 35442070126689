import { Directive, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: 'input[select-on-focus]',
})
export class SelectOnFocusDirective {
  @HostListener('focus', ['$event']) selectOnFocus($event) {
    $event.target.select();
  }
}
