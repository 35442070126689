import { Injectable } from '@angular/core';
import { Paginacao } from '@app/shared/http/page';
import { EmpresaModel } from '@app/shared/models/cadastro/empresa.model';
import { AbstractSubmitService, RequestResponse } from '@shared/http';
import { EmpresaComboModel } from '@shared/models';
import { Observable } from 'rxjs';

/**
 * Serviço para consulta na api.
 */
@Injectable()
export class EmpresaService extends AbstractSubmitService<EmpresaModel> {
  get endPoint(): string {
    return this.apiUrlCadastro + '/empresa';
  }

  getPage(query: string): Observable<RequestResponse<Paginacao<EmpresaModel>>> {
    return this.http.get<RequestResponse<Paginacao<EmpresaModel>>>(
      `${this.endPoint}?search=${query}`
    );
  }

  getEmpresaByUser() {
    return this.http.get<RequestResponse<EmpresaComboModel[]>>(this.endPoint + '/usuario');
  }

  getEmpresaList() {
    return this.http.get<RequestResponse<EmpresaModel[]>>(this.endPoint + '/list-all');
  }
}
