import { AppConfig } from '@app/app.config';
import { EmpresaModel } from '@app/shared/models/cadastro/empresa.model';
import { EmailType } from './enums/email-type.enum';
import { SendEmailRequest } from './models/send-email-request';
import { SendEmailModel } from './models/send-email.model';

export abstract class SendEmailUtil {
  static buildRelatorioRequests(model: SendEmailModel, empresa: EmpresaModel): SendEmailRequest[] {
    return model.toEmail.map(email => ({
      schema: AppConfig.USER?.dsSchema,
      empresaId: empresa.idEmpresa,
      type: EmailType.RELATORIO,
      subject: 'Documento emitido',
      toEmail: email.address,
      templateProps: {
        nome: email.nome,
        mensagem: model.mensagem,
        assinatura: empresa.dsAssinaturaEmail?.replace(/\n/g, '<br>') ?? '',
      },
      attachments: model.attachments ?? [],
    }));
  }
}
