/**
 * Enum para definição dos controles
 * de processos
 */
export enum SolicitacaoControlDefs {
  ID_SOLICITACAO = 'idSolicitacao',
  OBSERVACAO = 'dsObservacao',
  MOTIVO_REJEICAO = 'dsMotivoRejeicao',
  SITUACAO = 'stSolicitacao',
  TIPO = 'tpSolicitacao',
  PRIVILEGIO_SOLICITACAO_LIST = 'privilegioSolicitacaoList',
}

export enum ProcessosControlDefs {
  usuarioDestinoControl = 'usuarioDestino',
  motivoRecusaControl = 'motivoRecusa',
  messageControl = 'message',
  observacaoControl = 'observacao',
  usuarioControl = 'usuario',
}

export enum PrivilegioSolicitacaoControlDefs {
  ID = 'idPrivilegioSolicitacao',
  DESCRICAO = 'dsSolicitacao',
  APROVADORES = 'privilegioAprovadorList',
}
