import { AppConfig } from '@app/app.config';

/**
 * Classe abstrata com as urls da aplicação.
 */
export abstract class AbstractUrlService {
  /**
   * URl do backend da api de estoque.
   *
   * @type {string}
   */
  protected readonly apiUrlEstoque = AppConfig.API_URL_ESTOQUE;

  /**
   * URl do backend da api de cadastro.
   *
   * @type {string}
   */
  protected readonly apiUrlCadastro = AppConfig.API_URL_CADASTRO;

  /**
   * URl do backend da api de compras.
   *
   * @type {string}
   */
  protected readonly apiUrlCompras = AppConfig.API_URL_COMPRAS;

  /**
   * URl do backend da api de vendas.
   *
   * @type {string}
   */
  protected readonly apUrlVendas = AppConfig.API_URL_VENDAS;

  /**
   * URl do backend da api de preços.
   *
   * @type {string}
   */
  protected readonly apiUrlPrecos = AppConfig.API_URL_PRECOS;

  /**
   * URl do backend da api de financeiro.
   *
   * @type {string}
   */
  protected readonly apiUrlFinanceiro = AppConfig.API_URL_FINANCEIRO;

  /**
   * URl do backend da api do fiscal.
   *
   * @type {string}
   */
  protected readonly apiUrlFiscal = AppConfig.API_URL_FISCAL;

  /**
   * URl do backend da api do comissoes.
   *
   * @type {string}
   */
  protected readonly apiUrlComissoes = AppConfig.API_URL_COMISSOES;

  /**
   * URl do backend do astutus
   *
   * @type {string}
   */
  protected readonly apiUrlAstutus = AppConfig.ASTUTUS_API_URL;

  /**
   * URl do backend do monitor-nfe
   *
   * @type {string}
   */
  protected readonly apiUrlMonitorNfe = AppConfig.API_URL_MONITOR_NFE;

  /**
   * URl do backend do report
   *
   * @type {string}
   */
  protected readonly apiUrlReport = AppConfig.API_URL_REPORT;

  /**
   * URl do backend do oauth
   *
   * @type {string}
   */
  protected readonly apiUrlOauth = AppConfig.OAUTH_URL;

  /**
   * URl do backend do s3
   *
   * @type {string}
   */
  protected readonly apiUrlS3 = AppConfig.S3_API_URL;

  /**
   * URl do backend do dne
   *
   * @type {string}
   */
  protected readonly apiUrlDominio = AppConfig.API_URL_DOMINIO;
}
