import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { RequestResponse } from '@app/shared/http';
import { Observable } from 'rxjs';

@Injectable()
export class S3Service {
  private readonly endpoint = AppConfig.S3_API_URL;

  constructor(private http: HttpClient) {}

  presignedDownload(
    fileName: string,
    expiresInSeconds: number
  ): Observable<RequestResponse<string>> {
    const url = `${this.endpoint}/s3arquivo/download/presigned/${fileName}?expires=${expiresInSeconds}`;
    return this.http.get<RequestResponse<string>>(url);
  }
}
