import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { ProdutoParamsModel } from '@app/shared/components/vendas/adicionar-item/models/params.model';
import { Paginacao } from '@app/shared/http/page';
import { ProdutoComboModel, ProdutoPrecoModel } from '@app/shared/models';
import { AbstractSubmitService, RequestResponse } from '@shared/http';
import { Observable } from 'rxjs';
import { GetFirstProdutoLoteWithSaldoResponse } from './model/get-first-produto-lote-with-saldo-response';
import { PrecoDataRequestModel } from './model/preco-data-request.model';
import { PrecoDataModel } from './model/preco-data.model';
import { ProdutoKitModel } from './model/produto-kit-model';
import { ProdutoModel } from './model/produto-model';
import { ProdutoSaldoModel } from './model/produto-saldo.model';

/**
 * Serviço para consulta na api.
 */
@Injectable()
export class ProdutoService extends AbstractSubmitService<ProdutoModel> {
  get endPoint() {
    return `${this.apiUrlCadastro}/produto`;
  }

  /**
   * Busca um novo código de barras.
   */
  getCodigoBarras(): Observable<any> {
    return this.http.get(`${this.endPoint}/codigo-barras-caixa`);
  }

  /**
   * Busca o histórico do produto.
   */
  getHistorico(id): Observable<any> {
    return this.http.get(`${this.endPoint}/${id}/historico`);
  }

  /**
   * Busca o produto de acordo com a lista de IDs.
   * @deprecated Utilizar getProdutoPreco()
   */
  getPreco(params: {
    idProduto: number;
    idListaPrecoCliente: number;
    idListaPrecoVendedor: number;
    idLocalEstoque?: number;
  }): Observable<RequestResponse<any>> {
    return this.http.post<RequestResponse<any>>(`${this.endPoint}/preco`, params);
  }

  getPrecoData(request: PrecoDataRequestModel): Observable<RequestResponse<PrecoDataModel>> {
    return this.http.post<RequestResponse<PrecoDataModel>>(`${this.endPoint}/preco/dados`, request);
  }

  getPrecoDataList(
    request: PrecoDataRequestModel[]
  ): Observable<RequestResponse<PrecoDataModel[]>> {
    return this.http.post<RequestResponse<PrecoDataModel[]>>(
      `${this.endPoint}/preco/dados-list`,
      request
    );
  }

  /**
   * Busca se o lote do produto teve movimentação de estoque.
   */
  hasMovimentoEstoqueByIdProdutoAndIdLote(
    idProduto: number,
    idLote: number
  ): Observable<RequestResponse<boolean>> {
    return this.http.get<RequestResponse<boolean>>(
      `${this.apiUrlEstoque}/produto/${idProduto}/lote/${idLote}/has-movimento`
    );
  }

  /**
   * Busca se o produto teve movimentação de estoque.
   */
  hasMovimentoEstoqueByIdProduto(idProduto: number): Observable<RequestResponse<boolean>> {
    return this.http.get<RequestResponse<boolean>>(
      `${this.apiUrlEstoque}/produto/${idProduto}/has-movimento`
    );
  }

  /**
   * Relaciona o produto com o fornecedor.
   */
  relacionarProdutos(data, id): Observable<any> {
    return this.http.put<RequestResponse<any>>(`${this.endPoint}/fornecedor/${id}`, data);
  }

  /**
   * Busca o saldo de estoque por empresa.
   */
  getSaldoEstoqueByEmpresa(idProduto, idEmpresa): Observable<RequestResponse<ProdutoSaldoModel[]>> {
    return this.http.get<RequestResponse<ProdutoSaldoModel[]>>(
      `${this.apiUrlEstoque}/produto/saldo-estoque/${idProduto}/${idEmpresa}`
    );
  }

  /**
   * Busca o saldo de estoque por empresa.
   */
  getSaldoEstoqueByLocalEstoque(
    idProduto: number,
    idLocalEstoque: number
  ): Observable<RequestResponse<ProdutoSaldoModel[]>> {
    return this.http.get<RequestResponse<ProdutoSaldoModel[]>>(
      `${this.apiUrlEstoque}/produto/saldo-estoque/${idProduto}/local-estoque/${idLocalEstoque}`
    );
  }

  getSaldoEstoqueProdutoList(
    localEstoqueId: number,
    produtoIdList: number[]
  ): Observable<RequestResponse<ProdutoSaldoModel[]>> {
    return this.http.post<RequestResponse<ProdutoSaldoModel[]>>(
      `${this.apiUrlEstoque}/produto/saldo-estoque/local-estoque/${localEstoqueId}`,
      produtoIdList
    );
  }

  /**
   * Busca lista de saldo de estoque por produto
   */
  getSaldoEstoqueByProdutoList(
    idProdutoList: number[]
  ): Observable<RequestResponse<ProdutoSaldoModel[]>> {
    return this.http.post<RequestResponse<ProdutoSaldoModel[]>>(
      `${this.apiUrlEstoque}/produto/saldo-estoque`,
      idProdutoList
    );
  }

  /**
   * Busca lista de saldo de estoque por produto em todas as empresas
   */
  getSaldoEstoqueTotalProduto(idProduto: number): Observable<RequestResponse<ProdutoSaldoModel[]>> {
    return this.http.get<RequestResponse<ProdutoSaldoModel[]>>(
      `${this.apiUrlEstoque}/produto/saldo-estoque/${idProduto}/all`
    );
  }

  getProdutoPrecoByProdutoList(
    idProdutoList: number[]
  ): Observable<RequestResponse<ProdutoPrecoModel[]>> {
    return this.http.post<RequestResponse<ProdutoPrecoModel[]>>(
      `${AppConfig.API_URL_CADASTRO}/produto-preco/batch`,
      idProdutoList
    );
  }

  /**
   * Busca o produto combobox.
   */
  findCombobox(idProduto): Observable<any> {
    return this.http.get(
      `${this.endPoint}/combobox?search=pag:1,orderField:dsProduto,orderType:ASC,tpSituacao:1,idProduto:${idProduto}`
    );
  }

  findComboboxByQuery(query): Observable<RequestResponse<Paginacao<ProdutoComboModel>>> {
    return this.http.get<RequestResponse<Paginacao<ProdutoComboModel>>>(
      `${this.endPoint}/combobox?search=pag:1,orderField:dsProduto,orderType:ASC,${query}`
    );
  }

  /**
   * Busca o produto de acordo com a lista de IDs.
   */
  findWithFiguraFiscal(
    idNaturezaOperacao: number,
    idPessoa: number,
    idProdutoList: number[]
  ): Observable<RequestResponse<any[]>> {
    const params = new HttpParams()
      .set('idNaturezaOperacao', `${idNaturezaOperacao}`)
      .set('idPessoa', `${idPessoa}`);
    return this.http.post<RequestResponse<any[]>>(`${this.endPoint}/tributacao`, idProdutoList, {
      params,
    });
  }

  /**
   * Retorna todas as informações do produto em lote
   * @param params
   */
  findAllInfoBatch(params: ProdutoParamsModel): Observable<RequestResponse<any>> {
    return this.http.post<RequestResponse<any>>(`${this.endPoint}/venda/batch`, params);
  }

  /**
   * Retorna todas as informações do produto em lote
   * (incluido informações fiscais para produtos inativos)
   * @param params
   */
  findAllInfoRemessaBatch(params: ProdutoParamsModel): Observable<RequestResponse<any>> {
    return this.http.post<RequestResponse<any>>(`${this.endPoint}/venda/remessa/batch`, params);
  }

  /**
   * Busca lista de flags stQtdFracionada correspondente
   * a lista de IDs dos produtos.
   */
  findFlagQtdFracionadaInIdList(idProdutoList: number[]): Observable<RequestResponse<any[]>> {
    return this.http.post<RequestResponse<any[]>>(
      `${this.endPoint}/find/list/qtd-fracionada`,
      idProdutoList
    );
  }

  /**
   * Busca lista informando se os produtos passados possuem promoção
   */
  hasPromocaoProdutoInIdList(idProdutoList: number[]): Observable<RequestResponse<any[]>> {
    return this.http.post<RequestResponse<any[]>>(
      `${this.apiUrlCadastro}/lista-preco/find/list/promocao`,
      idProdutoList
    );
  }

  /**
   * busca pelos produtos de acordo com a query enviada.
   */
  getProdutosFromQuery(query): Observable<any> {
    return this.http.get(`${this.endPoint}?search=orderType:ASC,orderField:dsProduto,${query}`);
  }

  /*
   * Faz a atualização dos produtos da integração com B2B
   */
  updateProdutosIntegracaoB2b(data): Observable<any> {
    return this.http.put<RequestResponse<any>>(`${this.endPoint}/update/integracao-b2b/`, data);
  }

  /*
   * Reenvia os produtos com falha para a integração com b2b
   */
  reenviaProdutosIntegracaoB2b(data): Observable<any> {
    return this.http.post<RequestResponse<any>>(`${this.endPoint}/b2b/reenvia-produtos`, data);
  }

  /**
   * Busca a lista e produtos que compôem um kit
   */
  getProdutoKitList(idProduto: number): Observable<RequestResponse<ProdutoKitModel[]>> {
    const url = `${this.endPoint}/venda/produto-kit/${idProduto}`;
    return this.http.get<RequestResponse<ProdutoKitModel[]>>(url);
  }

  /**
   * Retorna a lista de produtos relacionados pelos códigos de fornecedor informados
   * @param idPessoa
   * @param nrCodigoFornecedorList
   */
  findAllByIdPessoaAndNrCodigoFornecedor(
    idPessoa: number,
    nrCodigoFornecedorList: string[]
  ): Observable<RequestResponse<any>> {
    return this.http.post<RequestResponse<any>>(
      `${this.endPoint}/fornecedor/${idPessoa}`,
      nrCodigoFornecedorList.map(nrCodigoFornecedor => ({ nrCodigoFornecedor }))
    );
  }

  findListById(idProdutoList: number[]): Observable<RequestResponse<ProdutoModel[]>> {
    return this.http.post<RequestResponse<ProdutoModel[]>>(`${this.endPoint}/list`, idProdutoList);
  }

  getFirstLoteWithSaldo(
    localEstoqueId: number,
    produtoId: number
  ): Observable<RequestResponse<GetFirstProdutoLoteWithSaldoResponse>> {
    return this.http.get<RequestResponse<GetFirstProdutoLoteWithSaldoResponse>>(
      `${this.apiUrlEstoque}/produto/${produtoId}/local-estoque/${localEstoqueId}/lote-com-saldo`
    );
  }
}
