import { ItemComboboxModel } from '@app/shared/models/item-combobox';

export class SituacaoSolicitacao {
  static readonly AGUARDANDO_APROVACAO = 0;

  static readonly APROVADO = 1;

  static readonly REPROVADO = 2;

  private static map = {
    [SituacaoSolicitacao.AGUARDANDO_APROVACAO]: 'Aguardando aprovação',
    [SituacaoSolicitacao.APROVADO]: 'Aprovado',
    [SituacaoSolicitacao.REPROVADO]: 'Reprovado',
  };

  /**
   * Obtém a descrição da situação
   *
   * @param stSolicitacao
   */
  static getDescricao(stSolicitacao: number): string {
    return this.map[stSolicitacao];
  }

  /**
   * Retorna os valores enumerados como lista
   *
   * @returns
   */
  static values(): ItemComboboxModel[] {
    return [
      { value: this.AGUARDANDO_APROVACAO, label: this.getDescricao(this.AGUARDANDO_APROVACAO) },
      { value: this.APROVADO, label: this.getDescricao(this.APROVADO) },
      { value: this.REPROVADO, label: this.getDescricao(this.REPROVADO) },
    ];
  }
}
