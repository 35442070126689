export class Messages {
  /**
   * Lista de mensagens exibidas no toast.
   */
  static readonly MESSAGES = {
    DIALOG: {
      DELETE: 'Confirma a exclusão deste registro?',
    },
    TOAST: {
      DELETE_SUCCESS: 'Removido com sucesso',
      DELETE_ERROR: 'Erro ao remover',
      DELETE_ERROR_RELATED: 'Não é possível excluir, este registro está sendo utilizado',
      SAVE_SUCCESS: 'Salvo com sucesso',
      SAVE_ERROR: 'Erro ao salvar',
      INVALID_DATA: 'Dados inválidos',
      FIND_ERROR: 'Erro ao buscar dados',
      TIME_OUT_ERROR: 'Erro ao buscar dados. Verifique a conexão com a internet!',
      CURRENT_DATE_GREATER_BEGIN_DATE:
        'Somente é possível excluir figura fiscal com inicio de vigência superior a data atual',
      CERTIFICADOR_VENCIDO_ERROR: 'Certificado usado para envio está vencido',
    },
    FIELD: {
      REQUIRED: 'Campo obrigatório',
      INVALID: 'Campo inválido',
      DUPLICATED: 'Informação já cadastrada',
      DUPLICATED_CONSTANT: 'Constante já cadastrada',
      EMAIL_CADASTRADO: 'Email já cadastrado',
      CRC_CADASTRADO: 'Crc já cadastrado',
      DOC_CADASTRADO: 'Documento já cadastrado',
    },
  };
}
