import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { EmpresaService } from '@app/pages/ambiente/empresa/empresa.service';
import { EmpresaModel } from '@app/shared/models/cadastro/empresa.model';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { SendEmailRequest } from './models/send-email-request';
import { SendEmailResponse } from './models/send-email-response';
import { SendEmailModel } from './models/send-email.model';
import { SendEmailUtil } from './send-email-util';

@Injectable()
export class EmailService {
  private readonly endpoint = `${AppConfig.API_URL_EMAIL}/emails`;

  constructor(private http: HttpClient, private empresaService: EmpresaService) {}

  sendRelatorio(model: SendEmailModel): Observable<SendEmailResponse[]> {
    return this.getEmpresa().pipe(
      switchMap(empresa => {
        const requests = SendEmailUtil.buildRelatorioRequests(model, empresa).map(request =>
          this.sendEmail(request)
        );

        return forkJoin(requests);
      })
    );
  }

  private sendEmail(request: SendEmailRequest): Observable<SendEmailResponse> {
    return this.http.post<SendEmailResponse>(this.endpoint, request);
  }

  private getEmpresa(): Observable<EmpresaModel> {
    return this.empresaService
      .search(AppConfig.EMPRESA_DATA?.idEmpresa)
      .pipe(switchMap(response => of(response.data)));
  }
}
