import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from "@angular/core";

@Injectable()
export class DateAdapterBr extends NativeDateAdapter {

  parse(value: any): Date | null {
    let parsed: Date = null;

    if (!value) {
      return null;
    }

    if (value.toString().length < 10) {
      return this.invalid();
    }

    if (value.toString().length > 10) {
      value = value.toString().substr(0, 10);
    }

    const data = value.toString().split('/');

    if (data.length !== 3) {
      return this.invalid();
    }

    try {
      parsed = this.createDate(+(data[2]), +(data[1]) - 1, +(data[0]));
    } catch (e) {
      return this.invalid();
    }

    return parsed;
  }

}
