import { Component, HostListener } from '@angular/core';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-checkbox-cell',
  template: `
    <mat-checkbox
      [checked]="params.value"
      [disabled]="disabled"
      color="accent"
      (change)="onChange($event)"
    ></mat-checkbox>
  `,
  styles: [
    `
      :host {
        margin: auto;
      }
    `,
  ],
})
export class CheckboxComponent implements ICellRendererAngularComp {
  params: ICellRendererParams;

  disabled = false;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.disabled = this.params.data ? this.params.data.disabled : false;
  }

  onChange(event: MatLegacyCheckboxChange) {
    this.params.value = event.checked;
    this.params.node?.setDataValue(this.params.colDef.field, event.checked);
    this.params.refreshCell?.();

    if ('onChange' in this.params && typeof this.params.onChange === 'function') {
      this.params.onChange(this.params);
    }
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    event.stopPropagation();
  }
}
