export enum KeyCode {
  ENTER = 'Enter',
  ESC = 'Escape',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  TAB = 'Tab',
  SHIFT = 'Shift',
  LEFT_ARROW = 'LeftArrow',
  UP_ARROW = 'UpArrow',
  RIGHT_ARROW = 'RightArrow',
  DOWN_ARROW = 'DownArrow',
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
  F4 = 'F4',
  F5 = 'F5',
  F9 = 'F9',
}
