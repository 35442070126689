import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { RequestResponse } from '@app/shared/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChannelsService {
  static readonly enpoint = `${AppConfig.API_URL_NOTIFICATION}/canais`;

  constructor(private readonly http: HttpClient) {}

  subscribe(channelId: string): Observable<RequestResponse<undefined>> {
    const url = `${ChannelsService.enpoint}/${channelId}/inscricao`;
    return this.http.put<RequestResponse<undefined>>(url, undefined);
  }

  unsubscribe(channelId: string): Observable<RequestResponse<undefined>> {
    const url = `${ChannelsService.enpoint}/${channelId}/inscricao`;
    return this.http.delete<RequestResponse<undefined>>(url);
  }
}
