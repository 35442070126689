import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { publicRoutes } from './pages/public/public.routes';
import { AuthLoginGuard } from './services/auth-login.guard';
import { AppAuthGuard } from './services/auth.guard';

const routes: Routes = [
  ...publicRoutes,
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [AuthLoginGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/useractivate/useractivate.module').then(m => m.UserActivateModule),
  },
  {
    path: 'userdemo',
    loadChildren: () => import('./pages/userdemo/userdemo.module').then(m => m.UserDemoModule),
  },
  {
    path: 'esquecersenha',
    loadChildren: () =>
      import('./pages/esquecer-senha/esquecer-senha.module').then(m => m.EsquecerSenhaModule),
  },
  {
    path: 'auth/alterarsenha',
    loadChildren: () =>
      import('./pages/alterarsenha/alterarsenha.module').then(m => m.AlterarSenhaModule),
  },
  {
    path: 'status',
    loadChildren: () => import('./pages/status/status.module').then(m => m.StatusModule),
  },
  {
    path: 'integracao/dominio',
    loadChildren: () =>
      import('./pages/integracao/integracao-dominio.module').then(m => m.IntegracaoDominioModule),
  },
  {
    path: 'anexos',
    loadChildren: () =>
      import('./pages/attachment/attachment.module').then(m => m.AttachmentModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/app/app.module').then(m => m.AppModule),
    canActivate: [AppAuthGuard],
    canActivateChild: [AppAuthGuard],
  },
  { path: '**', redirectTo: '/app' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  providers: [AuthLoginGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
