import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NavigationExtras, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { ProcessosService } from '@app/services/processos/processos.service';
import { FormularioUnsubscribeUtil } from '@bower-components/astutus-formulario/formulario-unsubscribe.util';
import { SessionStorageService } from '@services/sessionstorage.service';
import { isNullOrUndefined } from 'is-what';
import { Observable } from 'rxjs';
import { EmitirDocumentParams } from '../documents/emitir-document/emitir-document.component';

type NavigateProps = {
  pageContent: any;
  opts?: any[];
  extras?: NavigationExtras;
};

/**
 * Controle para adicionar rotas e navegar.
 */
@Injectable()
export class AppRoutingController extends FormularioUnsubscribeUtil {
  readonly FORBIDDEN_ACCESS_MESSAGE = 'Seu usuário não possui acesso a esse módulo';

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionService: SessionStorageService,
    private processosService: ProcessosService,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  /**
   * @deprecated
   * @param url
   */
  goTo(url) {
    this.router.navigate([url]);
  }

  /**
   * Recupera os dados do formulário de cadastro e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   */
  async goToCadastro(
    path,
    opts?: any[],
    type = 'pesquisa',
    extras: NavigationExtras = {}
  ): Promise<boolean> {
    let pageContent = this.sessionService.getItem(path);

    // Garante que as informações da rota sejam carregadas antes de tentar acessa-la.
    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, type).toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return false;
    }

    return this.navigate({ pageContent, opts, extras });
  }

  /**
   * Recupera os dados do formulário de edição e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  async goToUpdate(
    path,
    opts?: Array<any>,
    type = 'pesquisa',
    extras: NavigationExtras = {}
  ): Promise<boolean> {
    let pageContent = this.sessionService.getItem(path);
    let aux;

    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, type).toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return false;
    }

    aux = JSON.parse(JSON.stringify(pageContent));
    aux.formularioCadastro = null;
    return this.navigate({ pageContent: aux, opts: ['update', ...opts], extras });
  }

  /**
   * Recupera os dados do formulário de pesquisa e faz a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   */
  async goToPesquisa(path: string): Promise<boolean> {
    let pageContent = this.sessionService.getItem(path);

    // Garante que as informações da rota sejam carregadas antes de tentar acessa-la.
    if (isNullOrUndefined(pageContent)) {
      const formulario = (await this.getFormulario(path, 'pesquisa').toPromise()).data;
      sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
      pageContent = formulario;
    }

    const hasAccessByUrl = this.processosService.hasAccessByUrl(path);
    const hasAccessByFormularioPrincipal =
      !isNullOrUndefined(pageContent.formularioPrincipal) &&
      this.processosService.hasAccessByUrl(pageContent.formularioPrincipal.dsUrl);

    if (!hasAccessByUrl && !hasAccessByFormularioPrincipal) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return false;
    }

    return this.router.navigate([path]);
  }

  /**
   * Recupera os dados do formulário e emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  async goToDetails(
    path,
    opts?: Array<any>,
    type = 'cadastro',
    extras: NavigationExtras = {}
  ): Promise<boolean> {
    if (!this.processosService.hasAccessByUrl(path)) {
      this.showMessage(this.FORBIDDEN_ACCESS_MESSAGE);
      return false;
    }

    const pageContent = this.sessionService.getItem(path);

    if (!isNullOrUndefined(pageContent)) {
      const aux = JSON.parse(JSON.stringify(pageContent));
      aux.formularioCadastro = null;
      return this.navigate({ pageContent: aux, opts, extras });
    }

    const formulario = await this.getFormulario(path, type).toPromise();
    sessionStorage.setItem(formulario.data.dsUrl, JSON.stringify(formulario.data));
    const aux = JSON.parse(JSON.stringify(formulario.data));
    aux.formularioCadastro = null;
    return this.navigate({ pageContent: aux, opts, extras });
  }

  /**
   * Realiza o request pra buscar os dados da tela.
   */
  public getFormulario(dsUrl: string, type: string): Observable<any> {
    const url = `${AppConfig.ASTUTUS_API_URL}/formulario/${type}/consulta?dsUrl=${dsUrl}`;
    return this.http.get(url);
  }

  private navigate({ pageContent, opts, extras }: NavigateProps): Promise<boolean> {
    const url = pageContent.formularioCadastro
      ? pageContent.formularioCadastro.dsUrl
      : pageContent.dsUrl;

    if (opts) {
      return this.router.navigate([url, ...opts], extras);
    }

    if (!opts) {
      return this.router.navigate([url], extras);
    }
  }

  /**
   * Abre PDF do relatório
   * @param data
   */
  navigeteToReport(data): Promise<boolean> {
    const nmFilename = data.nmFilename.replace('/s3arquivo/download/', '').replace('.pdf', '');
    return this.goToCadastro('/app/pdf-viewer/file-name', [nmFilename]);
  }

  gotToEmitirDocumento(params: EmitirDocumentParams): Promise<boolean> {
    return this.goToCadastro(
      '/app/documento/emitir/new',
      [params.documentType, params.fieldType, params.fieldName, params.filterValue],
      'cadastro'
    );
  }

  private showMessage(msg: string): void {
    this.snackBar.open(msg, 'OK', {
      duration: 3000,
    });
  }
}
