/* eslint-disable no-use-before-define */
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { VIRTUAL_SCROLL_STRATEGY } from '@angular/cdk/scrolling';
import { Directive, ElementRef, Input, OnChanges, forwardRef } from '@angular/core';
import fromResize from '@app/shared/utils/resize-observer';
import { EMPTY, of as ofObservable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { DynamicSizeVirtualScrollStrategy } from './dynamic-size-virtual-scroll.strategy';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export function _dynamicSizeVirtualScrollStrategyFactory(
  dynamicSizeDir: DynamicSizeVirtualScrollDirective
) {
  return dynamicSizeDir._scrollStrategy;
}

@Directive({
  selector: 'cdk-virtual-scroll-viewport[dynamicSize]',
  providers: [
    {
      provide: VIRTUAL_SCROLL_STRATEGY,
      useFactory: _dynamicSizeVirtualScrollStrategyFactory,
      deps: [forwardRef(() => DynamicSizeVirtualScrollDirective)],
    },
  ],
  exportAs: 'dynamicSize',
  standalone: true,
})
export class DynamicSizeVirtualScrollDirective implements OnChanges {
  @Input('dynamicSize')
  set itemSize(value: (index: number) => number) {
    this._itemSize = value;
  }

  get itemSize() {
    return this._itemSize;
  }

  private _itemSize = (index: number) => 50;

  @Input()
  set minBufferPx(value: number) {
    this._minBufferPx = coerceNumberProperty(value);
  }

  get minBufferPx() {
    return this._minBufferPx;
  }

  private _minBufferPx = 100;

  _scrollStrategy = new DynamicSizeVirtualScrollStrategy(this._itemSize, this._minBufferPx);

  constructor(private element: ElementRef<HTMLElement>) {
    fromResize(this.element.nativeElement)
      .pipe(
        takeUntilDestroyed(),
        switchMap(entry => {
          const contentRect = entry[0]?.contentRect;
          if (!contentRect) return EMPTY;
          return ofObservable(contentRect);
        }),
        debounceTime(100)
      )
      .subscribe(() => this._scrollStrategy.checkViewportSize());
  }

  ngOnChanges(): void {
    this._scrollStrategy.updateItemAndBufferSize(this._itemSize, this._minBufferPx);
  }
}
