<div [ngClass]="getClass()">
  <h1 mat-dialog-title>{{ data.title }}</h1>
  <div mat-dialog-content class="content">
    <mat-icon *ngIf="data.type">{{ getIcon() }}</mat-icon>
    <article class="body">
      <p *ngFor="let content of data.content">{{ content }}</p>
    </article>
  </div>
  <mat-dialog-actions align="end">
    <button
      *ngFor="let button of data.buttons"
      mat-raised-button
      [mat-dialog-close]="button.value"
      [color]="button.color"
    >
      {{ button.text }}
    </button>
  </mat-dialog-actions>
</div>
