import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { OauthService } from '@app/services/oauth/oauth.service';
import { HttpMethods } from '@bower-components/astutus-formulario/http-methods';
import { BehaviorSubject, from, Observable, throwError } from 'rxjs';
import { catchError, filter, mergeMap, take } from 'rxjs/operators';

/**
 * Interceptor para injetar token nas requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Serviço de oauth.
   */
  private _oauthService: OauthService;

  private refreshTokenInProgress = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  /**
   * Carrega a classe de oauth por conta de dependencia ciclica.
   */
  get oauthService() {
    if (!this._oauthService) {
      this._oauthService = this.injector.get(OauthService);
    }

    return this._oauthService;
  }

  /**
   * Construtor.
   */
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !this.isRequestFormUrlEncoded(request) &&
      !this.isRequestOctetStream(request) &&
      !this.isRequestMultiPart(request)
    ) {
      request = this.addAuthenticationToken(request);
    }

    if (this.isRequestMultiPart(request)) {
      request = request.clone({ headers: AppConfig.getHttpHeader() });
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (
          this.isRequestFormUrlEncoded(request) ||
          this.isRequestOctetStream(request) ||
          this.isRequestMultiPart(request) ||
          error.status !== 401
        ) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          return this.refreshTokenSubject.pipe(
            filter(result => result !== null),
            take(1),
            mergeMap(() => next.handle(this.addAuthenticationToken(request)))
          );
        }

        this.refreshTokenInProgress = true;
        this.refreshTokenSubject.next(null);

        const isDeleteMethod = request.method === HttpMethods.DELETE;
        const pushSubscriptionUrl = `${AppConfig.API_URL_NOTIFICATION}/usuarios/push-subscription`;
        const ignoreRefreshToken = isDeleteMethod && request.url.endsWith(pushSubscriptionUrl);

        if (!ignoreRefreshToken) {
          return from(this.refreshToken()).pipe(
            catchError(err => {
              this.refreshTokenInProgress = false;
              return this.throwError(err);
            }),
            mergeMap(() => {
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(AppConfig.OAUTH_DATA);

              return next.handle(this.addAuthenticationToken(request));
            })
          );
        }

        if (error.status === 401) {
          this.oauthService.doLogout(false);
        }

        this.refreshTokenInProgress = false;
      })
    );
  }

  private async doExchange() {
    const { status, data }: any = await this.oauthService.getPerfilUsuario().toPromise();

    if (status !== '200') {
      this.throwError(null);
      return;
    }

    AppConfig.OAUTH_DATA = await this.oauthService.exchangeToken(data).toPromise();

    localStorage.setItem('empresa', JSON.stringify(data));
    localStorage.setItem('oauth', JSON.stringify(AppConfig.OAUTH_DATA));
  }

  private async refreshToken() {
    const refreshToken = AppConfig.OAUTH_DATA ? AppConfig.OAUTH_DATA.refresh_token : null;
    AppConfig.OAUTH_DATA = await this.oauthService.refreshToken(refreshToken).toPromise();

    await this.doExchange();
  }

  /**
   * Adiciona o token de authorização no request.
   */
  private addAuthenticationToken(request) {
    if (!AppConfig.OAUTH_DATA) {
      return request;
    }

    return request.clone({ headers: AppConfig.getAuthorizationHeader() });
  }

  throwError(error) {
    this.refreshTokenInProgress = false;
    this.oauthService.doLogout();
    const description = error?.error?.error_description;

    return throwError(() => (description ? { message: description } : error));
  }

  /**
   * Retorna se o content type do request é x-www-form-urlencoded.
   */
  private isRequestFormUrlEncoded(request) {
    return this.getContentType(request) === 'application/x-www-form-urlencoded';
  }

  /**
   * Retorna se o content type do request é octet-stream.
   */
  private isRequestOctetStream(request) {
    return this.getContentType(request) === 'application/octet-stream';
  }

  /**
   * Retorna se o content type do request é multipart/form-data.
   */
  private isRequestMultiPart(request) {
    return this.getContentType(request) === 'multipart/form-data';
  }

  /**
   * Retorna o content type do request passado.
   */
  private getContentType(request) {
    return request.headers.get('Content-Type');
  }
}
