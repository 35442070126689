export enum EmailType {
  NOVO_USUARIO = 0,
  ATIVAR_USUARIO = 1,
  ALTERAR_SENHA = 2,
  ORCAMENTO = 3,
  PRE_VENDA = 4,
  PEDIDO_VENDA = 5,
  PEDIDO_COMPRA = 6,
  RELATORIO = 7,
}
