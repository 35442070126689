import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  /**
   * Construtor.
   */
  constructor() {}

  @HostListener('click') onClick() {
    Array.from(document.getElementsByClassName('mat-tooltip')).forEach(elem => elem.remove());
  }
}
