import { Component } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  template: '<mat-progress-bar mode="indeterminate"></mat-progress-bar>'
})
export class ProgressBarComponent {

  constructor() {}

}
