import { Injectable } from '@angular/core';

import { AutorizaSolicitacaoModel } from '@app/services/processos/models/autoriza-solicitacao.model';
import { SolicitacaoModel } from '@app/services/processos/models/solicitacao.model';
import { AbstractSubmitService, RequestResponse } from '@shared/http';
import { Observable } from 'rxjs';

@Injectable()
export class SolicitacaoService extends AbstractSubmitService<any> {
  get endPoint(): string {
    return `${this.apiUrlCadastro}/solicitacao`;
  }

  /**
   * Autoriza uma solicitação com usuário e senha
   */
  autorizar(data: AutorizaSolicitacaoModel): Observable<RequestResponse<SolicitacaoModel>> {
    return this.http.put<RequestResponse<SolicitacaoModel>>(`${this.endPoint}/autorizar`, data);
  }

  aprovar(idSolicitacao) {
    return this.http.put(`${this.endPoint}/aprovar/${idSolicitacao}`, {});
  }

  reprovar(idSolicitacao, motivo) {
    return this.http.put(`${this.endPoint}/reprovar/${idSolicitacao}`, motivo);
  }
}
