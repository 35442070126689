import { GridOptions } from 'ag-grid-community';

/**
 * Configura os aspectos da table do AgGrid, informando as opções mais utilizadas.
 *
 * @type GridOptions
 */
export const AgGridOptions: GridOptions = {
  rowHeight: 48,
  headerHeight: 56,
  rowSelection: 'single',
  suppressClickEdit: true,
  suppressMultiSort: true,
  suppressDragLeaveHidesColumns: true,
  suppressNoRowsOverlay: true,
  suppressRowDrag: true,
  enableCellTextSelection: true,
  localeText: { noRowsToShow: ' ' },
  tooltipShowDelay: 1000,
  tooltipHideDelay: 60000,
  defaultColDef: {
    resizable: false,
  },
  defaultColGroupDef: {
    marryChildren: true,
  },
};
