import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';

export abstract class FormManager {
  static updateAllValuesAndValidations(form: FormGroup | FormArray): void {
    if (form instanceof FormArray) {
      form.controls
        .filter(control => control instanceof FormGroup || control instanceof FormArray)
        .forEach((f: FormGroup | FormArray) => this.updateAllValuesAndValidations(f));
      return;
    }

    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        this.updateAllValuesAndValidations(control);
        return;
      }

      control.updateValueAndValidity();
    });
  }

  static markFormAsTouched(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(objectKey => {
      const formControl = form.controls[objectKey];
      formControl.markAsTouched();

      // implementação utilizada para disparar o statusChanges
      if (!formControl.valid) {
        formControl.updateValueAndValidity();
      }
    });
  }

  static markFormAsUntouched(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(objectKey => {
      const formControl = form.controls[objectKey];
      formControl.markAsPristine();
      formControl.markAsUntouched();
      formControl.markAsPending();
    });
  }
}
