<mat-form-field [floatLabel]="isLabelFloat ? 'auto' : 'never'" [appearance]="appearance">
  <input
    [errorStateMatcher]="matcher"
    type="text"
    matInput
    doubleTabInputMode
    #inputElement
    [placeholder]="label"
    [formControl]="formControl"
    (click)="onInputClick($event)"
    (focus)="onInputFocus($event)"
    (blur)="onBlur()"
    [value]="displayFn(formControlRef.value)"
    [matAutocomplete]="auto"
  />

  <mat-error *ngIf="formControlRef?.errors?.required">{{ requiredMessage }}</mat-error>
  <mat-error *ngIf="formControlRef?.errors?.alreadySelected"
    >Este item já foi selecionado</mat-error
  >

  <mat-error>
    <ng-content select="mat-error"></ng-content>
  </mat-error>

  <mat-hint *ngIf="hintMessage">{{ hintMessage }}</mat-hint>

  <button
    class="input-suffix-arrow-btn"
    type="button"
    mat-icon-button
    matSuffix
    tabindex="-1"
    [disabled]="inputElement.disabled"
    [ngClass]="{ openned: auto.isOpen }"
    (click)="onInputArrowClick($event)"
  >
    <mat-icon>keyboard_arrow_down</mat-icon>
  </button>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayFn.bind(this)"
    (optionSelected)="onSelect($event?.option?.value)"
    class="container-autocomplete"
  >
    <mat-progress-bar
      color="accent"
      mode="indeterminate"
      style="position: fixed; width: inherit"
      [style.visibility]="showProgress ? 'visible' : 'hidden'"
    ></mat-progress-bar>

    <!-- div utilizada para colocar o espaço ocupado pelo progress bar -->
    <div style="height: 5px"></div>

    <mat-option
      *ngFor="let item of filteredItems"
      [value]="item"
      [title]="displayFn(item)"
      #matoption
    >
      <div *ngIf="customTemplate" class="items-option">
        <ng-template
          [ngTemplateOutlet]="customTemplate"
          [ngTemplateOutletContext]="{ item: item }"
        ></ng-template>
      </div>
      <div *ngIf="!customTemplate">{{ displayFn(item) }}</div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
