import { AstutusComboboxInputs } from "./astutus-combobox-inputs";
import { EventEmitter, Directive } from "@angular/core";

@Directive()
export abstract class AstutusComboboxUtil extends AstutusComboboxInputs {
  /**
   * Lista de itens filtrados.
   */
  private _filteredItems: Array<any>;

  /**
   * Campo onde sera guardado o texto a ser pesquisado.
   */
  protected searchText: string | undefined;

  get filteredItems(): Array<any> {
    return this._filteredItems;
  }

  /**
   * Ao setar os itens filtrados emite um evento.
   */
  set filteredItems(value: Array<any>) {
    this._filteredItems = value;
    this.filteredItemsChange.emit(this.filteredItems);
  }

  /**
   * Evento emitido ao alterar a lista de itens filtrados.
   */
  readonly filteredItemsChange: EventEmitter<Array<any>> = new EventEmitter<
    Array<any>
  >();

  /**
   * Indica se deve exibir o progressbar.
   */
  showProgress = false;

  get hasRequest() {
    return !!this.url;
  }

  /**
   * Função que formata o item passado para o valor a ser exibido.
   */
  displayFn(item): string | undefined {
    let retorno = item || '';

    if (item) {
      try {
        if (this.displayLabel) {
          retorno = eval(this.displayLabel);
        } else if (this.selectedLabel) {
          retorno = eval(`item.${this.selectedLabel}`);
        }
      } catch (e) {}
    }

    return retorno;
  }

  /**
   * Habilita o progressbar.
   */
  enableProgressBar() {
    this.showProgress = true;
  }

  /**
   * Desabilita o progressbar.
   */
  disableProgressBar() {
    setTimeout(() => (this.showProgress = false), 500);
  }

  /**
   * Formata o documento do cliente ou fornecedor com a máscara
   */
  formatDocumento(value) {
    value = value.toString();
    let mask;

    if (value.length <= 14 && value.length > 11) {
      mask = '99.999.999/9999-99';
    } else if (value.length <= 11) {
      mask = '999.999.999-99';
    }
    return this.formatValue(value, mask);
  }

  /**
   * Formata um valor desejado de acordo com a máscara desejada
   */
  formatValue(value, mask) {
    value = value.toString();
    let output = [];
    let count = 0;
    for (let i = 0; i < mask.length; i++) {
      if (value[i + count] != undefined) {
        if (mask[i] == '9') {
          if (new RegExp(/[0-9]/).test(value[i + count])) {
            output.push(value[i + count]);
          }
        } else if (mask[i] == 'A') {
          if (new RegExp(/[a-zA-Z]/).test(value[i + count])) {
            output.push(value[i + count]);
          }
        } else if (mask[i] == undefined) {
          break;
        } else {
          output.push(mask[i]);
          count--;
        }
      }
    }

    return output.join('');
  }
}
