import { Directive, ElementRef, OnInit, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

const astutusComboboxTagName = 'ASTUTUS-COMBOBOX';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit {

  constructor(private element: ElementRef, @Optional() private ngControl: NgControl) { }

  ngOnInit(): void {
    const intervalEvent = setInterval(() => {
      if (this.element.nativeElement.offsetParent) {
        if (this.ngControl && this.ngControl.control) {
          this.ngControl.control.markAsUntouched();
        }
        setTimeout(() => {
          this.setAutofocus();
          clearInterval(intervalEvent);
        }, 500);
      }
    }, 100);
  }

  /**
   * Move o foco para o elemento.
   */
  private setAutofocus(): void {
    const nodeName = this.element.nativeElement.nodeName;

    if (nodeName === astutusComboboxTagName) {
      this.element.nativeElement.querySelector('input').click();
    } else {
      this.element.nativeElement.focus();
    }
  }

}
