import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SolicitacaoModel } from '@app/services/processos/models/solicitacao.model';
import { TipoSolicitacao } from '@app/services/processos/models/tipo-solicitacao.enum';
import { isNullOrUndefined } from 'is-what';
import { Observable } from 'rxjs';
import { ProcessosDialogComponent } from './dialog/processos-dialog.component';

@Injectable()
export class ProcessosService {
  /**
   * Construtor.
   */
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 3000,
    });
  }

  /**
   * Utilizado para verificar uma lista de permissões.
   * Verifica todos os processos da lista e abre modal para solicitar acesso aos processos que o usuário não possui permissão
   */
  async checkSolicitacao(solicitacao: SolicitacaoModel): Promise<boolean> {
    const processos = solicitacao.privilegioSolicitacaoList.filter(
      item => !this.hasAccess(item.dsConstanteMenu, item.dsConstanteAcao)
    );

    if (!processos || !processos.length) {
      return true;
    }

    return this.askPermission({ ...solicitacao, privilegioSolicitacaoList: processos }).toPromise();
  }

  /**
   * Verifica se o usuário possui acesso a funcionalidade e exibe a modal de requisição caso não tenha.
   *
   * @param object objeto contendo o menu, a acao, o usuario de destino da solicitação e o id do cliente
   * @param message mensagem exibida para o usuário que concede a permissão
   * @param listAprovadores lista auxiliar que pode incluir usuários que não tenham acesso prévio ao processo
   */
  async checkPermission(
    { menu, acao, idCliente = null },
    descricao,
    privilegioAprovadorList = null
  ) {
    const hasPermission = this.hasAccess(menu, acao);

    if (hasPermission) {
      return true;
    }

    const solicitacao: SolicitacaoModel = {
      dsObservacao: descricao,
      tpSolicitacao: TipoSolicitacao.REMOTA,
      pessoa: idCliente
        ? {
            idPessoa: idCliente,
            nmPessoa: null,
            nrDocumento: null,
          }
        : null,
      privilegioSolicitacaoList: [
        {
          dsConstanteMenu: menu,
          dsConstanteAcao: acao,
          dsSolicitacao: descricao,
          privilegioAprovadorList,
        },
      ],
    };

    return this.askPermission(solicitacao).toPromise();
  }

  /**
   * @returns Retorna se o usuário logado tem acesso a ação passada.
   */
  hasAccess(dsConstanteMenu: string, dsConstanteAcao: string): boolean {
    const menuList: any[] = JSON.parse(localStorage.getItem('menuList')) || [];

    const permissoes: Array<{
      dsConstanteMenu: string;
      dsConstanteAcao: string;
      dsConstante: string;
    }> = this.getPermissoes(menuList);

    return Boolean(
      permissoes.find(
        item => item.dsConstanteMenu === dsConstanteMenu && item.dsConstanteAcao === dsConstanteAcao
      )
    );
  }

  hasMenu(dsConstante: string) {
    const menuList: any[] = JSON.parse(localStorage.getItem('menuList'));
    return menuList.some(menu => menu.dsConstante === dsConstante);
  }

  /**
   * Retorna a lista de permissões do usuário com base no menu carregado
   */
  private getPermissoes(menuList: any[] = []): any {
    return menuList
      .map(item => {
        if (item.children && item.children.length) {
          return this.getPermissoes(item.children || []);
        }

        return item.acaoFormularioList.map(acao => ({
          dsConstanteMenu: item.dsConstante,
          dsConstanteAcao: acao.dsConstante,
          dsConstante: acao.dsAcaoFormulario,
        }));
      })
      .reduce((list, actual) => [...list, ...actual], []);
  }

  /**
   * Exibe um modal indicando que não possui acesso
   */
  askPermission(solicitacao: SolicitacaoModel): Observable<boolean> {
    return this.dialog
      .open(ProcessosDialogComponent, {
        width: '70%',
        disableClose: true,
        autoFocus: false,
        data: solicitacao,
      })
      .afterClosed();
  }

  /**
   * Verifica se o usuário possui acesso a partir da URL do formulário
   * @returns
   */
  hasAccessByUrl(dsUrl: string): boolean {
    const menuList: any[] = JSON.parse(localStorage.getItem('menuList')) || [];

    const allUsersGrantedAccess = ['/app/pdf-viewer/', '/app/documento/emitir/'];

    return (
      allUsersGrantedAccess.some(path => dsUrl.includes(path)) ||
      this.getFormularioUrlList(menuList).some(url => url.includes(dsUrl))
    );
  }

  /**
   * Obtém a lista de URL dos formulários
   * @param menuList
   */
  getFormularioUrlList(menuList: any[]): string[] {
    return menuList
      .map(menu => {
        if (menu.children && menu.children.length) {
          return this.getFormularioUrlList(menu.children);
        }

        return [
          (menu.formularioCadastroDTO || menu.formularioPesquisaDTO || {}).dsUrl,
          menu.formularioPesquisaDTO ? menu.formularioPesquisaDTO.formularioCadastro.dsUrl : null,
        ];
      })
      .reduce((acc, current) => [...acc, ...current], [])
      .filter(elem => !isNullOrUndefined(elem));
  }
}
