import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { AstutusComboboxModule } from '@bower-components/astutus-combobox/astutus-combobox.module';
import { AuthenticateDialogComponent } from '@app/services/processos/authenticate/authenticate-dialog.component';
import { SolicitacaoService } from '@app/pages/solicitacao/solicitacao.service';
import { ProcessosDialogComponent } from './dialog/processos-dialog.component';
import { ProcessosService } from './processos.service';

@NgModule({
  imports: [SharedModule, AstutusComboboxModule],
  declarations: [ProcessosDialogComponent, AuthenticateDialogComponent],
  providers: [ProcessosService, SolicitacaoService],
})
export class ProcessosModule {}
