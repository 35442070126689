import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../../app.config';

/**
 * Serviço para consulta na api.
 */
@Injectable()
export class DominioService {
  /**
   * Construtor.
   */
  constructor(private http: HttpClient) {}

  /**
   * Busca Endereço pelo CEP.
   */
  public searchByCep(cep): Observable<any> {
    return this.http.get(`${AppConfig.API_URL_DOMINIO}/cep/${cep}`);
  }
}
