import { Component, Inject, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ColDef, GridOptions } from 'ag-grid-community';
import { AgGridRenderer } from '../../table/ag-grid/ag-grid-renderer';
import { AgGridOptions } from '../../table/ag-grid/ag-grid-options';

declare let Chart;

@Component({
  selector: 'app-historico-produto-dialog',
  templateUrl: './historico-produto-dialog.component.html',
  styleUrls: ['./historico-produto-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoricoProdutoDialogComponent implements OnInit {
  @ViewChild('historicoProduto', { static: true })
  private canvasHistoricoProduto;

  @ViewChild('chartLegend', { static: true })
  private legend;

  historicoQuantidadeList = [];

  tpGrafico: UntypedFormControl;

  historicoValorList = [];

  labels = [];

  myChart;

  lineChartData;

  form: UntypedFormGroup;

  rowData = [];

  bottomData = [];

  gridOptions: GridOptions = {
    alignedGrids: [],
    ...AgGridOptions,
    suppressHorizontalScroll: true,
  };

  bottomOptions: GridOptions = {
    alignedGrids: [],
    ...AgGridOptions,
    rowHeight: 40,
    suppressHorizontalScroll: true,
    suppressRowClickSelection: true,
  };

  columnDefs: ColDef[] = [
    {
      headerName: 'Local de estoque',
      field: 'localEstoque.dsLocalEstoque',
      cellStyle: () => {
        return { 'text-align': 'left' };
      },
    },
    {
      headerName: 'Saldo disponível',
      field: 'nrSaldo',
      cellStyle: () => {
        return { 'text-align': 'center' };
      },
      valueFormatter: params => this.agGridRenderer.decimal(params, '1.3-3'),
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<HistoricoProdutoDialogComponent>,
    private agGridRenderer: AgGridRenderer,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    if (Array.isArray(this.gridOptions.alignedGrids)) {
      this.gridOptions.alignedGrids.push(this.bottomOptions);
    }

    if (Array.isArray(this.bottomOptions.alignedGrids)) {
      this.bottomOptions.alignedGrids.push(this.gridOptions);
    }
  }

  ngOnInit() {
    this.tpGrafico = new UntypedFormControl('0');

    this.form = new UntypedFormGroup({
      dsCodigoBarras: new UntypedFormControl(),
      dsProduto: new UntypedFormControl(),
      historicoMovimentacaoList: new UntypedFormControl([]),
      idProduto: new UntypedFormControl(),
      marca: new UntypedFormControl(),
      nrEstoqueDisponivel: new UntypedFormControl(),
      nrQuantidadePedidos: new UntypedFormControl(),
      saldoEstoqueList: new UntypedFormControl([]),
      stVendaEstoqueNegativo: new UntypedFormControl(),
      tpControleLote: new UntypedFormControl(),
      unidadeMedida: new UntypedFormControl(),
      vlCustoMedio: new UntypedFormControl(),
      vlUnitario: new UntypedFormControl(),
      vlCustoNotaFiscal: new UntypedFormControl({ value: 0, disabled: true }),
      vlCustoGerencial: new UntypedFormControl({ value: 0, disabled: true }),
      vlVenda: new UntypedFormControl({ value: 0, disabled: true }),
    });

    this.getHistoricoProduto();

    this.tpGrafico.valueChanges.subscribe(data => {
      this.onChangeTipoGrafico(data);
    });
  }

  getHistoricoProduto() {
    this.form.patchValue(this.data);

    this.rowData = this.form.get('saldoEstoqueList').value;
    this.bottomData = [
      {
        dsLocalEstoque: 'Saldo de estoque total:',
        nrSaldoEstoque: this.form.get('nrEstoqueDisponivel').value,
      },
    ];
    this.setDataCanvas();
  }

  setDataCanvas() {
    const quantidadeCompras = [];
    const quantidadeVendas = [];
    const valorCompras = [];
    const valorVendas = [];
    const meses = [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ];

    this.form.value.historicoMovimentacaoList.forEach(item => {
      quantidadeCompras.unshift(item.nrQuantidadeCompras);
      quantidadeVendas.unshift(item.nrQuantidadeVendas);
      valorCompras.unshift(item.nrValorCompras);
      valorVendas.unshift(item.nrValorVendas);

      this.historicoQuantidadeList.push(quantidadeCompras);
      this.historicoQuantidadeList.push(quantidadeVendas);

      this.historicoValorList.push(valorCompras);
      this.historicoValorList.push(valorVendas);

      const mes = +item.dtMovimento.substr(5, 2);
      this.labels.unshift(meses[mes - 1]);
    });
    this.initChart();
  }

  onChangeTipoGrafico(tipoGrafico) {
    if (+tipoGrafico === 0) {
      this.changeDataCanvas(this.historicoQuantidadeList);
    } else {
      this.changeDataCanvas(this.historicoValorList);
    }
  }

  changeDataCanvas(array) {
    [this.lineChartData.datasets[0].data, this.lineChartData.datasets[1].data] = array;

    this.myChart.update();
  }

  initChart() {
    const context = this.canvasHistoricoProduto.nativeElement.getContext('2d');

    this.lineChartData = {
      labels: this.labels,
      datasets: [
        {
          label: 'Compras',
          backgroundColor: '#b2b2b2',
          borderColor: '#b2b2b2',
          color: '#b2b2b2',
          data: this.historicoQuantidadeList[0],
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          pointHoverRadius: 10,
          pointHitRadius: 10,
          pointBorderWidth: 1,
        },

        {
          label: 'Vendas',
          backgroundColor: '#03a9f4',
          borderColor: '#03a9f4',
          color: '#03a9f4',
          data: this.historicoQuantidadeList[1],
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          pointHoverRadius: 10,
          pointHitRadius: 10,
          pointBorderWidth: 1,
        },
      ],
    };

    this.myChart = new Chart(context, {
      type: 'line',
      data: this.lineChartData,
      options: {
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    });

    this.legend.nativeElement.innerHTML = this.myChart.generateLegend();
  }
}
