<h1 mat-dialog-title>Autenticação</h1>
<div mat-dialog-content row-wrap [formGroup]="form">
  <astutus-combobox
    col-12
    id="autorizador"
    label="Usuário"
    [select-one-result]="true"
    [formControlRef]="form.get(this.controlDefs.USER)"
    selected-label="usuario.nmUsuario"
    [items]="aprovadorList"
  >
  </astutus-combobox>

  <mat-form-field col-12>
    <input
      matInput
      autofocus
      placeholder="Senha"
      type="password"
      [formControlName]="this.controlDefs.PASSWORD"
      disabled="processandoAutorizacao"
      (keyup)="onKeyUp($event)"
    />
    <mat-error *ngIf="form.get(controlDefs.PASSWORD).hasError('required')"
      >Campo obrigatório</mat-error
    >
    <mat-error *ngIf="form.get(controlDefs.PASSWORD).hasError('invalid')"
      >E-mail e/ou senha inválido(s)</mat-error
    >
  </mat-form-field>
</div>

<mat-dialog-actions align="end">
  <button mat-button color="warn" mat-dialog-close>CANCELAR</button>
  <button mat-button color="accent" (click)="authorize()">CONFIRMAR</button>
</mat-dialog-actions>
