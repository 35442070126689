import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { RequestResponse } from '@app/shared/http';
import { Paginacao } from '@app/shared/http/page';
import { Observable } from 'rxjs';

import { GetNotificacaoByIdApiResponse } from './models/get-notification-by-id-api-response';
import { GetNotficationsPageResponse } from './models/get-notifications-page-response';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private readonly endpoint = AppConfig.API_URL_NOTIFICATION;

  constructor(private http: HttpClient) {}

  getById(id: string): Observable<RequestResponse<GetNotificacaoByIdApiResponse>> {
    return this.http.get<RequestResponse<GetNotificacaoByIdApiResponse>>(
      `${this.endpoint}/notificacoes/${id}`
    );
  }

  getPage(page: number): Observable<RequestResponse<Paginacao<GetNotficationsPageResponse>>> {
    return this.http.get<RequestResponse<Paginacao<GetNotficationsPageResponse>>>(
      `${this.endpoint}/notificacoes`,
      {
        params: {
          page: page.toString(),
        },
      }
    );
  }

  markAsRead(id: string): Observable<RequestResponse<undefined>> {
    return this.http.put<RequestResponse<undefined>>(
      `${this.endpoint}/notificacoes/${id}/leitura`,
      {}
    );
  }

  maskAllAsRead(): Observable<RequestResponse<undefined>> {
    return this.http.put<RequestResponse<undefined>>(`${this.endpoint}/notificacoes/ler-todas`, {});
  }

  archiveAll(): Observable<RequestResponse<undefined>> {
    return this.http.put<RequestResponse<undefined>>(
      `${this.endpoint}/notificacoes/arquivar-todas`,
      {}
    );
  }

  subscribe(subscription: PushSubscription): Observable<RequestResponse<undefined>> {
    return this.http.put<RequestResponse<undefined>>(
      `${this.endpoint}/usuarios/push-subscription`,
      subscription
    );
  }

  unsubscribe(): Observable<RequestResponse<undefined>> {
    return this.http.delete<RequestResponse<undefined>>(
      `${AppConfig.API_URL_NOTIFICATION}/usuarios/push-subscription`
    );
  }
}
