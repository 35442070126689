import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFormatter } from './abstract-formatter';

/**
 * Diretiva para formatação de texto.
 *
 * Ex: 99999-999 -> format -> 85618-000
 */
@Directive({
  selector: '[textFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFormatDirective,
      multi: true,
    },
  ],
})
export class TextFormatDirective extends AbstractFormatter implements ControlValueAccessor {
  onTouched: any;

  onChange: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  /**
   * Carrega o padrão de formatação.
   */
  @Input('textFormat') format: string;

  writeValue(value: any): void {
    const formatedValue = this.formatValue(value || '', this.format);
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', formatedValue);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    const clearedValue = this.clearValue($event.target.value);
    const formatedValue = this.formatValue(clearedValue, this.format);

    $event.target.value = formatedValue;
    this.onChange(this.clearValue(formatedValue));
  }

  @HostListener('blur', ['$event'])
  onBlur($event: any) {
    if ($event.target.value.length !== this.format.length) {
      $event.target.value = '';
      this.onChange('');
    }

    this.onTouched();
  }
}
