<h1 mat-dialog-title>Histórico do produto</h1>
<div mat-dialog-content>
  <div row-inline class="produto-details">
    <h4 col-8>Produto: {{ form.get('dsProduto').value }}</h4>
    <h4 col-4 align="end">Código de barras: {{ form.get('dsCodigoBarras').value }}</h4>
  </div>

  <div row-inline>
    <form [formGroup]="form">
      <mat-form-field col-2>
        <input
          id="vlCustoNotaFiscal"
          matInput
          numerico
          placeholder="Custo nota fiscal"
          formControlName="vlCustoNotaFiscal"
        />
      </mat-form-field>

      <mat-form-field col-2>
        <input
          id="vlCustoGerencial"
          matInput
          numerico
          placeholder="Custo gerencial"
          formControlName="vlCustoGerencial"
        />
      </mat-form-field>

      <mat-form-field col-2>
        <input
          id="vlCustoGerencial"
          matInput
          numerico
          placeholder="Preço de venda"
          formControlName="vlVenda"
        />
      </mat-form-field>
    </form>
  </div>

  <div row-inline>
    <div col-5>
      <mat-card>
        <h4>Saldo de estoque</h4>
        <ag-grid-angular
          id="saldoEstoqueTopGrid"
          class="no-border-bottom top-grid"
          agGridResizeApi
          #topGrid
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [gridOptions]="gridOptions"
        >
        </ag-grid-angular>
        <ag-grid-angular
          id="saldoEstoqueBottomGrid"
          #bottomGrid
          agGridResizeApi
          class="no-border-bottom bottom-grid"
          [rowData]="bottomData"
          [gridOptions]="bottomOptions"
          headerHeight="0"
          [columnDefs]="columnDefs"
        >
        </ag-grid-angular>
      </mat-card>

      <div class="card-azul">
        <p>Quantidade em pedidos pendentes:</p>
        <p>{{ form.get('nrQuantidadePedidos').value | number: '1.3-3' }}</p>
      </div>
    </div>
    <div col-7>
      <div class="tittle-grafico">
        <h5>Histórico de vendas x compras(Últimos 12 meses)</h5>
        <mat-radio-group [formControl]="tpGrafico" id="tipoGrafico">
          <mat-radio-button color="primary" value="0">Quantidade</mat-radio-button>
          <mat-radio-button color="primary" value="1">Valor</mat-radio-button>
        </mat-radio-group>
      </div>
      <div>
        <canvas #historicoProduto></canvas>
        <div #chartLegend class="chart-legend"></div>
      </div>
    </div>
  </div>
</div>

<div mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="dialogRef.close(true)">OK</button>
</div>
