import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RadioButtonService {
  radioChangedSource = new Subject<any>();
  setChange = new Subject<any>();
  setDisableGroup = new Subject<any>();

  radioChanged$ = this.radioChangedSource.asObservable();
  setChange$ = this.setChange.asObservable();
  setDisableGroup$ = this.setDisableGroup.asObservable();

  changeRadioOption(data: any) {
    this.radioChangedSource.next(data);
  }

}
