import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { RequestResponse } from './request-response';
import { AppConfig } from '../../app.config';
import { AbstractUrlService } from './AbstractUrlService';

/**
 * @whatItDoes Realiza as ações de envio do formulário para api.
 *
 * @description Esta classe fornece de forma genérica mecanismos para salvar,
 * atualizar, buscar e deletar registros utilizando os métodos de envio http.
 *
 *
 * @class AbstractSubmitService<T>
 */
@Injectable()
export abstract class AbstractSubmitService<T> extends AbstractUrlService {
  /**
   * Construtor.
   */
  constructor(protected http: HttpClient) {
    super();
  }

  /**
   * Indique em qual endpoint as ações de submit devem ser executadas.
   *
   * @returns {string}
   */
  abstract get endPoint(): string;

  /**
   * Verifica se deve salvar ou atualizar um registro.
   *
   * @param {T} data Dados a serem submetidos.
   * @param {boolean} isEditableMode Indica se está em modo de edição.
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  saveOrUpdate(data: T, isEditableMode: boolean): Observable<RequestResponse<T>> {
    if (isEditableMode) {
      return this.update(data);
    }

    return this.save(data);
  }

  /**
   * Salva um novo registro.
   *
   * @param {T} data Dados a serem salvos.
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  save(data: T): Observable<RequestResponse<T>> {
    return this.http.post<RequestResponse<T>>(this.endPoint, data);
  }

  /**
   * Atualiza um registro.
   *
   * @param {T} data Dados a serem atualizados.
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  update(data: T): Observable<RequestResponse<T>> {
    return this.http.put<RequestResponse<T>>(this.endPoint, data);
  }

  /**
   * Busca um registro pelo id.
   *
   * @param {number} id
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  search(id: number): Observable<RequestResponse<T>> {
    return this.http.get<RequestResponse<T>>(this.endPoint + '/' + id);
  }

  /**
   * Remove um registro pelo id.
   *
   * @param {number} id
   *
   * @returns {Observable<RequestResponse<T>>}
   */
  remove(id: number): Observable<RequestResponse<T>> {
    return this.http.delete<RequestResponse<T>>(this.endPoint + '/' + id);
  }
}
