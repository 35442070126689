import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormManager } from '../validators';

@Directive({
  selector: '[stepperTouch]'
})
export class StepperTouchDirective implements OnChanges {
  @Input() stepperTouch: boolean;
  @Input() stepControl: UntypedFormGroup;

  ngOnChanges(changes: SimpleChanges): void {
    const isFormGroup = this.stepControl instanceof UntypedFormGroup;
    const isTouched = changes.stepperTouch.currentValue;

    if (isFormGroup && isTouched) {
      FormManager.markFormAsTouched(this.stepControl);
    }
  }
}
