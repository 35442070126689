import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNullOrUndefined, isNumber } from 'is-what';
import { Comparable } from '../types/comparable';

export class CustomValidators {
  static negativeValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isNegative = control.value < 0;
      return isNegative ? { negativeValue: { value: control.value } } : null;
    };
  }

  static biggerThan(comparable: AbstractControl | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let isBiggerThan: boolean;

      if (comparable instanceof AbstractControl) {
        isBiggerThan = control.value > comparable.value;
      }

      if (isNumber(comparable)) {
        isBiggerThan = control.value > comparable;
      }

      return isBiggerThan ? { biggerThan: { value: control.value } } : null;
    };
  }

  static smallerOrEqualsThan(comparable: AbstractControl | number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (isNullOrUndefined(control.value) || control.value === '') {
        return null;
      }

      let isSmallerOrEqualsThan: boolean;

      if (comparable instanceof AbstractControl) {
        isSmallerOrEqualsThan = control.value <= comparable.value;
      }

      if (isNumber(comparable)) {
        isSmallerOrEqualsThan = control.value <= comparable;
      }
      return isSmallerOrEqualsThan ? { smallerOrEqualsThan: { value: control.value } } : null;
    };
  }

  static validCpfAndCnpj(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      if (control.value.length <= 11) {
        return this.isValidCPF(control);
      }
      return this.isValidCNPJ(control);
    };
  }

  private static isValidCPF(control: AbstractControl) {
    if (!control.value) {
      return null;
    }

    let strCPF = control.value;
    let soma = 0;
    let resto;
    strCPF = strCPF.toString().replace(/[^0-9]/g, '');

    if (
      strCPF === '00000000000' ||
      strCPF === '11111111111' ||
      strCPF === '22222222222' ||
      strCPF === '33333333333' ||
      strCPF === '44444444444' ||
      strCPF === '55555555555' ||
      strCPF === '66666666666' ||
      strCPF === '77777777777' ||
      strCPF === '88888888888' ||
      strCPF === '99999999999'
    ) {
      return { validCPF: { value: control.value } };
    }

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(strCPF.substring(i - 1, i), null) * (11 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(strCPF.substring(9, 10), null)) {
      return { validCPF: { value: control.value } };
    }

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(strCPF.substring(i - 1, i), null) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }
    if (resto !== parseInt(strCPF.substring(10, 11), null)) {
      return { validCPF: { value: control.value } };
    }
    return null;
  }

  private static isValidCNPJ(control: AbstractControl) {
    if (!control.value) {
      return null;
    }

    let strCNPJ = control.value;
    strCNPJ = strCNPJ.toString().replace(/[^0-9]/g, '');

    let tamanho = strCNPJ.length - 2;
    let numeros = strCNPJ.substring(0, tamanho);
    const digitos = strCNPJ.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado !== +digitos.charAt(0)) {
      return { validCNPJ: { value: control.value } };
    }

    tamanho += 1;
    numeros = strCNPJ.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== +digitos.charAt(1)) {
      return { validCNPJ: { value: control.value } };
    }

    return null;
  }

  static validCPF(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.isValidCPF(control);
    };
  }

  static validCNPJ(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return this.isValidCNPJ(control);
    };
  }

  static trimValue(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const trim = control.value.toString().trim();
      if (trim === '') {
        control.setValue('');
        if (control.getError('required')) {
          return { required: true };
        }
      }
      return null;
    };
  }

  static justNumber(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (/[^\d]+/g.exec(control.value)) {
        const value = control.value.toString().replace(/[^0-9]/g, '');
        control.setValue(value);
        if (value === '') {
          if (control.getError('required')) {
            return { required: true };
          }
        }
      }
      return null;
    };
  }

  static justNumberAndPoint(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (/[^\d.]+/g.exec(control.value)) {
        const value = control.value.toString().replace(/[^0-9.]/g, '');
        control.setValue(value);
        if (value === '') {
          if (control.getError('required')) {
            return { required: true };
          }
        }
      }
      return null;
    };
  }

  static alreadySelected(comparable: Comparable): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return comparable(control) ? { alreadySelected: { value: control.value } } : null;
    };
  }

  static matchOrNumeric(textToMatch: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      if (control.value === textToMatch || /^[0-9]+$/.test(control.value)) {
        return null;
      }

      return { matchOrNumeric: { value: control.value } };
    };
  }

  /**
   * Cria uma validação customizada.
   *
   * @param comparable Função a ser chamada para fazer a validação.
   * @param errorName Nome do erro a ser verificado no html.
   */
  static custom(comparable: Comparable, errorName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const errorData = {};
      errorData[errorName] = { value: control.value };

      return comparable(control) ? errorData : null;
    };
  }
}
