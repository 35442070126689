import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk .+ failed/;

    if (error.code !== "messaging/permission-blocked") {
      console.error(error);
    }

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
