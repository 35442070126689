import { Input, EventEmitter, Output, Directive } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

let nextUniqueId = 0;

@Directive()
export abstract class AstutusComboboxInputs {
  /**
   * Parametros adicionais.
   */
  @Input('additional-parameters') additionalParameters = [];

  /**
   * ID do componente, caso não passado é gerado um.
   */
  @Input() id = `astutus-combobox-${++nextUniqueId}`;

  /**
   * Placeholder do componente.
   */
  @Input() label: string;

  /**
   * Campo opcional, onde é passado o campo a ser exibido, quando não for passado, é usado o selectedLabel;
   *
   * Deve-se utilizar o valor "item" como objeto do for. Ex:
   *
   * display-label="item.id + ' - ' + item.descricao"
   */
  @Input('display-label') displayLabel: string;

  /**
   * Indica qual o atributo será exibido ao selecionar um item.
   * Se não for passado nada, o objeto inteiro é atribuido.
   */
  @Input('selected-label') selectedLabel: string;

  /**
   * Utilizado como condição onde permite permanecer selecionado apenas se o campo passado existir em algum dos itens do novo request;
   * 
   * Campo opcional, caso a condição retorne falsa o Form do combo é resetado;
   *
   * Se não passado nada, o objeto continua sem a condição;
   *
   * Exemplo de uso: selected-value="idProdutoLote"
   */
 @Input('selected-value') selectedValue: string;

  /**
   * Indica se o campo é obrigatório.
   */
  @Input() required = false;

  /**
   * Form control do componente.
   */
  @Input() formControlRef = new UntypedFormControl();

  /**
   * Form control controlado pela tela.
   */
  formControl = new UntypedFormControl();

  /**
   * Mensagem a ser exibida para a validação de obrigatoriedade.
   */
  @Input('required-message') requiredMessage = 'Campo obrigatório';

  /**
   * Mensagem a ser exibida no hint.
   */
  @Input('hint-message') hintMessage: string;

  /**
   * Atributo name para validação.
   */
  @Input() name: string;

  /**
   * Template que pode ser usado para mostrar informações no combobox
   * EX:
   * <ng-template #customTemplate let-objeto="item">
        <astutus-combobox-title>{{ objeto.pessoa.nmPessoa }}</astutus-combobox-title>
        <astutus-combobox-content>
          {{ objeto.pessoa.nrDocumento }}, {{objeto.nrTelefone }}
        </astutus-combobox-content>
        <astutus-combobox-content>
          {{ objeto.clienteEnderecoList[0].nmLogradouro }},
          {{ objeto.clienteEnderecoList[0].dsBairro }},
          {{ objeto.clienteEnderecoList[0].nmMunicipio }} -
          {{ objeto.clienteEnderecoList[0].sgEstado }}
        </astutus-combobox-content>
    </ng-template>
  */
  @Input() customTemplate: string;

  /**
   * Utilizado para definir qual a ordem de tabulação deve ser seguida.
   */
  @Input() tabindex = 0;

  /**
   * URL para busca dos dados de acesso.
   */
  @Input() url: string;

  /**
   * Indica por qual atributo será ordenado.
   */
  @Input('order-field') orderField: string;

  /**
   * Qual a ordenação utilizada na paginação.
   * Valores: ASC, DESC
   * Por padrão: ASC
   */
  @Input('order-type') orderType = 'ASC';

  /**
   * Campo pelo qual será realizado a busca ao digitar.
   */
  @Input('search-field') searchField: string;

  /**
   * Atributo usado para indicar se deve selecionar automaticamente o item
   * quando a busca no combo retornar apenas um resultado
   */
  @Input('select-one-result') selectOneResult = false;

  /**
   * Atributo usado para indicar se deve usar o estilo
   * de label float no combobox, por default é exibido
   */
  @Input('is-label-float') isLabelFloat = true;

  /**
   * Para evitar o erro disparado pelo Angular:
   * "Expression has changed after it was checked".
   */
  @Input('disabled')
  set disabled(value: boolean) {
    Promise.resolve().then(() => {
      if (value) {
        this.formControlRef.disable();
        this.formControl.disable();
      } else {
        this.formControlRef.enable();
        this.formControl.enable();
      }
    });
  }

  /**
   * Ao mudar o estatus disabled, altera o Input.
   */
  get disabled(): boolean {
    return this.formControlRef.disabled;
  }

  /**
   * Lista de itens.
   */
  private _items: Array<any>;

  /**
   * Evento emitido quando os itens do combobox são modificados.
   */
  readonly itemsChange: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();

  /**
   * Lista de itens a serem exibidos na listagem.
   */
  get items(): Array<any> {
    return this._items;
  }

  /**
   * Ao alterar a lista de itens, emite um evento com a lista de itens.
   */
  @Input()
  set items(value: Array<any>) {
    this._items = value;
    this.itemsChange.emit(this.items);
  }

  /**
   * Contem o valor selecionado.
   */
  get selected(): object | string | number {
    return this.formControlRef.value;
  }

  @Input()
  set selected(item: object | string | number) {
    if (this.formControlRef.value !== item) {
      this.formControl.setValue(item);
      this.formControlRef.setValue(item, { emitEvent: false });
    }
  }

  @Input() appearance = 'legacy';

  /**
   * Evento para quando o valor selecionado mudar.
   */
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();

  /**
   * Evento para quando estiver marcado a opção de selecionar
   * automaticamente o item quando a busca retornar apenas um resultado
   */
  @Output() selectOneResultEvent: EventEmitter<any> = new EventEmitter();

  /**
   * Evento para quando o valor selecionado mudar por uma ação do usuário.
   */
  @Output() optionSelected = new EventEmitter();

  /**
   * Evento disparado ao sair do campo
   */
  @Output() blur = new EventEmitter();
}
