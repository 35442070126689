import { CdkVirtualScrollViewport, VirtualScrollStrategy } from '@angular/cdk/scrolling';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

type ItemInfo = { index: number; offset: number };

type ItemCountOptions = {
  viewportSize: number;
  startIndex: number;
  endIndex: number;
  reverse?: boolean;
};

type ItemCount = {
  totalItems: number;
  totalItemsHeight: number;
  lastItemIndex: number;
  lastItemOffset: number;
};

/** Implementação da estratégia de rolagem virtual para items com altura dinâmica. */
export class DynamicSizeVirtualScrollStrategy implements VirtualScrollStrategy {
  private readonly _scrolledIndexChange = new Subject<number>();

  /** Implementado como parte da interface VirtualScrollStrategy. */
  scrolledIndexChange: Observable<number> = this._scrolledIndexChange.pipe(distinctUntilChanged());

  /** O viewport anexado. */
  private _viewport: CdkVirtualScrollViewport | null = null;

  /** O método que obtém a altura de cada item da lista. */
  private _itemSize: (index: number) => number;

  /** A quantidade mínima de buffer renderizado além do viewport (em pixels). */
  private _minBufferPx: number;

  constructor(itemSize: (index: number) => number, minBufferPx: number) {
    this._itemSize = itemSize;
    this._minBufferPx = minBufferPx;
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  attach(viewport: CdkVirtualScrollViewport) {
    this._viewport = viewport;
    this._updateTotalContentSize();
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  detach() {
    this._scrolledIndexChange.complete();
    this._viewport = null;
  }

  /** Atualiza o método que obtém a altura dos itens e a quantidade de buffer. */
  updateItemAndBufferSize(itemSize: (index: number) => number, minBufferPx: number) {
    this._itemSize = itemSize;
    this._minBufferPx = minBufferPx;
  }

  /** Verifica alterações no tamanho do viewport */
  checkViewportSize(): void {
    this._viewport?.checkViewportSize();
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  onContentScrolled() {
    this._updateRenderedRange();
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  onDataLengthChanged() {
    this._updateTotalContentSize();
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  onContentRendered() {
    /* no-op */
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  onRenderedOffsetChanged() {
    /* no-op */
  }

  /** Implementado como parte da interface VirtualScrollStrategy. */
  scrollToIndex(index: number, behavior: ScrollBehavior): void {
    if (!this._viewport) return;
    const offset = this._calculateContentHeight(0, index - 1);
    this._viewport.scrollToOffset(offset, behavior);
  }

  /** Atualiza a altura total utilizada pelos items. */
  private _updateTotalContentSize() {
    if (!this._viewport) return;

    const lastIndex = this._viewport.getDataLength() - 1;
    const totalContentSize = this._calculateContentHeight(0, lastIndex);
    this._viewport.setTotalContentSize(totalContentSize + 1);
    this._updateRenderedRange();
  }

  /** Atualiza a faixa/range dos itens a serem renderizados no viewport. */
  private _updateRenderedRange() {
    if (!this._viewport) return;

    const renderedRange = this._viewport.getRenderedRange();
    const newRange = { start: renderedRange.start, end: renderedRange.end };
    const lastIndex = this._viewport.getDataLength() - 1;
    const scrollOffset = this._viewport.measureScrollOffset();
    const viewportSize = this._viewport.getViewportSize();
    const firstVisibleItem = this._getFirstVisibleItem(scrollOffset, lastIndex);

    newRange.start = firstVisibleItem.index;

    let renderedContentOffset = firstVisibleItem.offset;
    const startBuffer = scrollOffset - renderedContentOffset;

    if (startBuffer < this._minBufferPx && newRange.start > 0) {
      const maxItemsInBuffer = this._getMaxItemCountInViewport({
        viewportSize: this._minBufferPx,
        startIndex: 0,
        endIndex: firstVisibleItem.index - 1,
        reverse: true,
      });

      newRange.start = maxItemsInBuffer.lastItemIndex;
      renderedContentOffset -= maxItemsInBuffer.totalItemsHeight;
    }

    const maxVisibleItems = this._getMaxItemCountInViewport({
      viewportSize: viewportSize + startBuffer + this._minBufferPx,
      startIndex: firstVisibleItem.index,
      endIndex: lastIndex,
    });

    newRange.end = maxVisibleItems.lastItemIndex + 1;

    this._viewport.setRenderedRange(newRange);
    this._viewport.setRenderedContentOffset(renderedContentOffset);
    this._scrolledIndexChange.next(firstVisibleItem.index);
  }

  /** Obtém o primeiro item visível na tela/viewport. */
  private _getFirstVisibleItem(scrollOffset: number, endIndex: number): ItemInfo {
    const maxItems = this._getMaxItemCountInViewport({
      viewportSize: scrollOffset,
      startIndex: 0,
      endIndex,
    });

    return {
      offset: maxItems.lastItemOffset,
      index: maxItems.lastItemIndex,
    };
  }

  /** Calcula a quatidade máxima de items que cabem no viewport. */
  private _getMaxItemCountInViewport(options: ItemCountOptions): ItemCount {
    const range = this._createRange(options.startIndex, options.endIndex + 1);
    if (options.reverse) range.reverse();

    let totalHeight = 0;
    let totalItems = 0;
    let lastItemIndex = 0;
    let lastItemHeight = 0;

    if (this._itemSize) {
      range.forEach(index => {
        if (totalHeight >= options.viewportSize) return;
        lastItemIndex = index;
        lastItemHeight = this._itemSize(index);
        totalItems += 1;
        totalHeight += lastItemHeight;
      });
    }

    let lastItemOffset = totalHeight - lastItemHeight;
    if (options.reverse) {
      lastItemOffset = options.viewportSize - totalHeight;
    }

    return {
      totalItemsHeight: totalHeight,
      totalItems,
      lastItemIndex,
      lastItemOffset,
    };
  }

  /** Calcula a altura dos items. */
  private _calculateContentHeight(startIndex: number, endIndex: number): number {
    if (!this._itemSize) return 0;
    const range = this._createRange(startIndex, endIndex + 1);
    return range.reduce((total, index) => {
      return total + this._itemSize(index);
    }, 0);
  }

  /** Cria uma lista com os valores de um intervalo. Ex. start: 3, end: 6, result: [3, 4, 5]. */
  private _createRange(start: number, end: number): number[] {
    return Array.from({ length: end - start }, (v, k) => k + start);
  }
}
