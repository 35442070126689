import { HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';

/**
 * Definição da classe.
 */
export class AppConfig {
  /**
   * Dados de oauth.
   */
  static OAUTH_DATA: any;

  /**
   * Dados da empresa selecionada.
   */
  static EMPRESA_DATA: any = {};

  /**
   * Dados do usuário.
   */
  static USER: any;

  /**
   * Id de acesso.
   */
  static CLIENT_ID = 'frontend-nexuscloud';

  /**
   * Chave de acesso.
   */
  static CLIENT_SECRET = 'Wz4AVDkfDWUH';

  static VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;

  /**
   * Url de autenticação.
   */
  static OAUTH_URL = environment.OAUTH_URL;

  /**
   * Url do frontend.
   */
  static API_URL_FRONTEND = environment.API_URL_FRONTEND;

  /**
   * Url da api.
   */
  static API_URL_CADASTRO = environment.API_URL_CADASTRO;

  /**
   * Url da api de compras.
   */
  static API_URL_COMPRAS = environment.API_URL_COMPRAS;

  /**
   * Url da api de estoque.
   */
  static API_URL_ESTOQUE = environment.API_URL_ESTOQUE;

  /**
   * Url da api de financeiro.
   */
  static API_URL_FINANCEIRO = environment.API_URL_FINANCEIRO;

  /**
   * Url da api de vendas.
   */
  static API_URL_VENDAS = environment.API_URL_VENDAS;

  /**
   * Url da api preços
   */
  static API_URL_PRECOS = environment.API_URL_PRECOS;

  /**
   * Url da api fiscal
   */
  static API_URL_FISCAL = environment.API_URL_FISCAL;

  /**
   * Url da api comissoes
   */
  static API_URL_COMISSOES = environment.API_URL_COMISSOES;

  /**
   * Url da api.
   */
  static ASTUTUS_API_URL = environment.ASTUTUS_API_URL;

  /**
   * Url da api de s3.
   */
  static S3_API_URL = environment.S3_API_URL;

  /**
   * Url da api de report.
   */
  static API_URL_REPORT = environment.API_URL_REPORT;

  /**
   * Url da api do presto.
   */
  static API_URL_PRESTO = environment.API_URL_PRESTO;

  /**
   * Url da api do sync.
   */
  static API_URL_SYNC = environment.API_URL_SYNC;

  /**
   * Url da api de monitor.
   */
  static API_URL_MONITOR_NFE = environment.API_URL_MONITOR_NFE;

  /**
   * Url da API do Dominio
   */
  static API_URL_DOMINIO = environment.API_URL_DOMINIO;

  /**
   * Url da api IO
   */
  static API_URL_IO = environment.API_URL_IO;

  /**
   * Url da api queue manager
   */
  static API_URL_QUEUE_MANAGER = environment.API_URL_QUEUE_MANAGER;

  /**
   * Url da api do e-commerce
   */
  static API_URL_ECOMMERCE = environment.API_URL_ECOMMERCE;

  /**
   * Url da api do task manager
   */
  static API_URL_TASK_MANAGER = environment.API_URL_TASK_MANAGER;

  /**
   * Url da api de notificação
   */
  static API_URL_NOTIFICATION = environment.API_URL_NOTIFICATION;

  /**
   * Url da api de e-mail
   */
  static API_URL_EMAIL = environment.API_URL_EMAIL;

  /**
   * Url da api de auditoria
   */
  static API_URL_AUDITORIA = environment.API_URL_AUDITORIA;

  /**
   * Url do suporte
   */
  static URL_SUPORTE = 'https://nexuscloud.movidesk.com/';

  /**
   * Url para integração com domínio
   */
  static URL_INTEGRACAO_DOMINIO = `${environment.INTEGRACAO_DOMINIO_URL}`;

  /**
   * Url para visualizar versões do sistema.
   */
  static URL_VERSOES = 'https://nexuscloud.movidesk.com/kb/article/367758/novidades-nexuscloud';

  /**
   * Url para artigo sobre o servidor de impressão automatico.
   */
  static URL_ARTIGO_SERVIDOR_IMPRESSAO =
    'https://nexuscloud.movidesk.com/kb/pt-br/article/471303/servidor-de-impressao-nexuscloud';

  /**
   * Retorna o headers com o `content-type`.
   * @deprecated
   */
  static getHeaders() {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${AppConfig.OAUTH_DATA.access_token}`);
  }

  /**
   * Retorna o headers com apenas o Authorization.
   */
  static getHeader() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  static getAuthorizationHeader(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${AppConfig.OAUTH_DATA.access_token}`);
  }

  static getHttpHeader(): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${AppConfig.OAUTH_DATA.access_token}`);
  }
}
