import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';

/**
 * Resource para controle dos requests de acesso.
 */
@Injectable()
export class PerfilamentoService {
  /**
   * URL da api.
   */
  private baseUrl = `${AppConfig.OAUTH_URL}/perfil`;

  /**
   * Construtor.
   */
  constructor(private http: HttpClient) {}

  /**
   * Carrega os dados de perfilamento.
   */
  public getPerfilamento(): any {
    return this.http.get(`${this.baseUrl}/${AppConfig.OAUTH_DATA.access_token}`, {
      headers: this.getOauthHeader(),
    });
  }

  /**
   * Retorna os cabeçalhos para login e refresh.
   */
  private getOauthHeader() {
    return new HttpHeaders({
      Authorization: `Bearer ${AppConfig.OAUTH_DATA.access_token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
  }
}
