import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppConfig } from '@app/app.config';
import { SolicitacaoService } from '@app/pages/solicitacao/solicitacao.service';
import { AuthenticateDialogComponent } from '@app/services/processos/authenticate/authenticate-dialog.component';
import { AuthenticateDialogParams } from '@app/services/processos/authenticate/params.model';
import { PrivilegioSolicitacaoModel } from '@app/services/processos/models/privilegio-solicitacao.model';
import { SolicitacaoModel } from '@app/services/processos/models/solicitacao.model';
import { TipoSolicitacao } from '@app/services/processos/models/tipo-solicitacao.enum';
import { take } from 'rxjs/operators';
import { SituacaoSolicitacao } from '../models/situacao-solicitacao.enum';
import { PrivilegioSolicitacaoControlDefs, SolicitacaoControlDefs } from './controldefs';

@Component({
  selector: 'app-processos-dialog',
  templateUrl: './processos-dialog.component.html',
  styleUrls: ['./processos-dialog.component.scss'],
})
export class ProcessosDialogComponent implements OnInit {
  get controlDefs() {
    return SolicitacaoControlDefs;
  }

  /**
   * enum dos controls de processos
   */
  get privilegioSolicitacaoControlDefs() {
    return PrivilegioSolicitacaoControlDefs;
  }

  /**
   * enum dos controls de solicitacao
   */
  get solicitacaoAcessoControlDefs() {
    return SolicitacaoControlDefs;
  }

  /**
   * retorna o usuarioDetino do form
   */

  get usuarioDestino() {
    // return this.validatorForm.get(this.privilegioSolicitacaoControlDefs.usuarioControl).value;
    return null;
  }

  processandoAutorizacao = false;

  usuarioForm: UntypedFormGroup;

  validatorForm: UntypedFormGroup;

  solicitacaoForm: UntypedFormGroup;

  aprovadorList = [];

  private timeout;

  /**
   * Construtor.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public solicitacao: SolicitacaoModel,
    public dialogRef: MatDialogRef<ProcessosDialogComponent>,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private solicitacaoService: SolicitacaoService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.initValidator();
    /* this.validatorForm.patchValue(this.data);
    if (this.data.usuarioDestino) {
      this.solicitarAcesso();
    } */
  }

  get isSolicitacaoRecusada(): boolean {
    const stSolicitacao = this.validatorForm.get(this.controlDefs.SITUACAO).value;
    return stSolicitacao === SituacaoSolicitacao.REPROVADO;
  }

  get isAguardandoAprovacao(): boolean {
    const stSolicitacao = this.validatorForm.get(this.controlDefs.SITUACAO).value;
    return stSolicitacao === SituacaoSolicitacao.AGUARDANDO_APROVACAO;
  }

  get isSolicitacaoLocal(): boolean {
    const tpSolicitacao = this.validatorForm.get(this.controlDefs.TIPO).value;
    return tpSolicitacao && tpSolicitacao === TipoSolicitacao.LOCAL;
  }

  get isProcessing(): boolean {
    return (
      (this.processandoAutorizacao && !this.isSolicitacaoRecusada) || this.isAguardandoAprovacao
    );
  }

  get urlSolicitacao() {
    return `${AppConfig.API_URL_CADASTRO}/solicitacao`;
  }

  get privilegioSolicitacaoControls() {
    const array = this.validatorForm.get(
      this.controlDefs.PRIVILEGIO_SOLICITACAO_LIST
    ) as UntypedFormArray;
    return array.controls;
  }

  getProcessingTitle(): string {
    const idSolicitacao = this.validatorForm.get(this.controlDefs.ID_SOLICITACAO).value;
    const processingTitle = 'Aguardando autorização';

    if (!idSolicitacao) {
      return processingTitle;
    }

    return `${processingTitle} – N.º ${idSolicitacao}`;
  }

  /**
   * Inicia o formulário.
   */
  initValidator() {
    const processoFormList: UntypedFormGroup[] = this.solicitacao.privilegioSolicitacaoList.map(
      processo => {
        return this.fb.group({
          [this.privilegioSolicitacaoControlDefs.ID]: [],
          [this.privilegioSolicitacaoControlDefs.DESCRICAO]: [
            { value: processo.dsSolicitacao, disabled: true },
          ],
          [this.privilegioSolicitacaoControlDefs.APROVADORES]: [],
        });
      }
    );

    this.validatorForm = this.fb.group({
      [this.controlDefs.ID_SOLICITACAO]: this.fb.control({ value: null, disabled: true }),
      [this.controlDefs.PRIVILEGIO_SOLICITACAO_LIST]: this.fb.array(processoFormList),
      [this.controlDefs.MOTIVO_REJEICAO]: this.fb.control({ value: null, disabled: true }),
      [this.controlDefs.SITUACAO]: [],
      [this.controlDefs.TIPO]: [],
      [this.controlDefs.OBSERVACAO]: [],
    });
    /* this.validatorForm.addControl(this.controlDefs.usuarioDestinoControl, new FormControl(null));
    this.validatorForm.addControl(
      this.controlDefs.messageControl,
      new FormControl({ value: null, disabled: true })
    );
    this.validatorForm.addControl(
      this.controlDefs.motivoRecusaControl,
      new FormControl({ value: null, disabled: true })
    );
    this.validatorForm.addControl(this.controlDefs.observacaoControl, new FormControl(''));
    this.validatorForm.addControl(this.controlDefs.usuarioControl, new FormControl(null)); */
  }

  cancelar() {
    this.fecharModal();
  }

  async fecharModal(success = false) {
    clearTimeout(this.timeout);
    const idSolicitacao = this.validatorForm.get(this.controlDefs.ID_SOLICITACAO).value;
    if (idSolicitacao && !success && !this.isSolicitacaoRecusada) {
      try {
        await this.cancelarSolicitacao(idSolicitacao).toPromise();
      } catch (error) {
        console.error(error);
        this.showMessage(`Não foi possível cancelar a solicitação`);
      }
    }

    this.dialogRef.close(success);
  }

  async solicitarAcesso(tipoSolicitacao = TipoSolicitacao.REMOTA) {
    try {
      if (this.processandoAutorizacao) {
        return;
      }

      this.processandoAutorizacao = true;

      const dsObservacao = this.validatorForm.get(this.controlDefs.OBSERVACAO).value;

      const solicitacao: SolicitacaoModel = {
        pessoa: this.solicitacao.pessoa,
        dsObservacao,
        privilegioSolicitacaoList: this.solicitacao.privilegioSolicitacaoList,
        tpSolicitacao: tipoSolicitacao,
      };

      const savedSolicitacao = (await this.solicitacaoService.save(solicitacao).toPromise()).data;
      this.validatorForm.patchValue(savedSolicitacao);

      if (tipoSolicitacao === TipoSolicitacao.REMOTA) {
        this.verificarSolicitacao();
      }

      this.processandoAutorizacao = false;
    } catch (e) {
      this.processandoAutorizacao = false;
      this.showMessage(`Erro ao solicitar acesso: ${e.error.message}`);
    }
  }

  private async verificarSolicitacao() {
    const idSolicitacao = this.validatorForm.get(this.controlDefs.ID_SOLICITACAO).value;
    const solicitacao = (await this.solicitacaoService.search(idSolicitacao).toPromise()).data;

    this.validatorForm.patchValue(solicitacao);

    if (solicitacao.stSolicitacao === SituacaoSolicitacao.AGUARDANDO_APROVACAO) {
      this.timeout = setTimeout(() => this.verificarSolicitacao(), 1000);
      return;
    }

    if (solicitacao.stSolicitacao === SituacaoSolicitacao.APROVADO) {
      this.fecharModal(true);
      this.showMessage('Solicitação aprovada');
    }
  }

  showMessage(message: string) {
    this.snackBar.open(message, 'OK', {
      duration: 3000,
    });
  }

  async autorizarAcesso() {
    await this.solicitarAcesso(TipoSolicitacao.LOCAL);

    const privilegioSolicitacaoList = this.validatorForm.get(
      this.controlDefs.PRIVILEGIO_SOLICITACAO_LIST
    ).value as PrivilegioSolicitacaoModel[];

    const data: AuthenticateDialogParams = {
      idSolicitacao: this.validatorForm.get(this.controlDefs.ID_SOLICITACAO).value,
      privilegioAprovadorList: privilegioSolicitacaoList
        .map(item => item.privilegioAprovadorList)
        .reduce((currentList, actualList) => [...currentList, ...actualList], []),
    };

    this.dialog
      .open(AuthenticateDialogComponent, {
        width: '400px',
        data,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (solicitacao: SolicitacaoModel) => {
        this.processandoAutorizacao = false;

        if (!solicitacao) {
          await this.cancelarSolicitacao(data.idSolicitacao).toPromise();
          this.validatorForm.get(this.controlDefs.TIPO).reset();
          this.validatorForm.get(this.controlDefs.SITUACAO).reset();
          this.validatorForm.get(this.controlDefs.ID_SOLICITACAO).reset();
          return;
        }

        if (solicitacao.stSolicitacao === SituacaoSolicitacao.APROVADO) {
          this.fecharModal(true);
          this.showMessage('Solicitação aprovada');
          return;
        }

        this.validatorForm.patchValue(solicitacao);
      });
  }

  private cancelarSolicitacao(idSolicitacao) {
    return this.solicitacaoService.remove(idSolicitacao);
  }
}
