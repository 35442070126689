import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Utilitário para controle dos unsubscribes.
 *
 * Exemplo:
 *
 * const myFormControl = new FormControl(null);
 *
 * myFormControl.valueChanges.takeUntil(this.unsubscribe).subscribe(value => console.log(value));
 *
 */
@Directive()
export abstract class FormularioUnsubscribeUtil implements OnDestroy {
  /**
   * Subject para controle dos unsubscribes.
   */
  protected unsubscribe: Subject<void> = new Subject();

  /**
   * Construtor.
   */
  constructor() {}

  /**
   * Chamado ao destruir o componente.
   */
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
